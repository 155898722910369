import React, {Component} from 'react';
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {Col, Row, Form, Button, Modal as ModalB} from "react-bootstrap";
import {CREATE_USER, GET_TYPES, GET_USERS} from "../../sagas/user/types";
import { GET_CLIENTS, GET_CLIENT } from "../../sagas/client/types";
import { GET_SOCIETIES } from "../../sagas/society/types";
import { GET_CLIENTS_SOCIETIES } from "../../sagas/clientSociety/types";
import {
  USER_FIRST_NAME,
  USER_LAST_NAME,
  EMAIL,
  PHONE,
  PASSWORD,
} from "../../config/constants/formAttributes"

import {
  USER_TYPES,
  NO_CLIENT,
  NO_SOCIETY,
} from "../../config/constants/user"


const attributes = [
  USER_FIRST_NAME,
  USER_LAST_NAME,
  EMAIL,
  PHONE,
  PASSWORD,
]

class CreateUser extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalShow: false,
      selectClientShow: false,
      selectSocietyShow: false,
      clients: [],
      client: {},
      societies: [],
    };
  }
  componentWillMount(){
    this.props.getTypes();
    this.props.getUsers();
    this.props.getClients();
    this.props.getSocieties();
    this.props.getClientsSocieties();
    this.setState({societies: this.props.societies});
  }

  componentDidUpdate(prevProps){
    if(prevProps.client !== this.props.client && this.props.client ){
      this.setState({client: this.props.client, societies: this.props.client.societies});
    }
  }
  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }
  handleChangeClient(e) {
    this.handleChange(e);
    this.setState({client: {}, societies: this.props.societies});
    if(e.target.value !== NO_CLIENT) this.props.getClient(e.target.value);
  }
  handleChangeType(e){
    this.handleChange(e);
    const clientType = this.props.types.find(obj => obj.name === USER_TYPES.CLIENT);
    if(e.target.value === clientType.id) {
      this.setState({
        selectClientShow: true,
        selectSocietyShow: true,
        clients: this.props.clients,
        client: {},
        societies: this.props.societies,
      });
    } else {
      this.setState({
        selectClientShow: false,
        selectSocietyShow: false,
        clients: [],
        client: {},
        societies: [],
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const obj = Object.fromEntries(data);
    let filterQuery = null;
    const clientType = this.props.types.find(objAux => objAux.id === obj.type_id);
    if(clientType) obj.type_name = clientType.name;
    if(obj.type_name === USER_TYPES.CLIENT){
      if(obj.client_id !== NO_CLIENT && obj.society_id !== NO_SOCIETY){
        const cs_id = this.props.client.societies.find(s => (s.id === obj.society_id));
        obj.client_society_id = cs_id.client_society_id;
      }
    }
    const user = this.props.users.filter(u => u.email === obj.email)
    if (user.length > 0) {
      this.props.closeModal();
      this.props.itemUser(obj);
      this.props.openAlertModal();

      setTimeout(() => {
        this.props.closeAlertModal();
      }, 3000) 
    }
    else {
    this.props.createUser(obj);
    this.props.getUsers(filterQuery);
    this.props.closeModal();
    }
  }

  showSelectClient(clientShow,clients){
    if(clientShow){
      const { t } = this.props;
      return  <Form.Group>
                <Form.Label>{t("translation:form>selectClient")}</Form.Label>
                <Form.Control
                  as="select"
                  className="text-capitalize"
                  name="client_id"
                  required
                  onChange={e => this.handleChangeClient(e)}>
                  <option key={0} value={NO_CLIENT}>{t("translation:general>noClient")}</option>
                    {clients.map((el, i) =>
                      <option key={i} value={el.id}>{el.name}</option>
                    )}
                </Form.Control>
                </Form.Group>
    }
  };
  showSelectSociety(societyShow,societies){
    if(societyShow){
      const { t } = this.props;
      return <Form.Group>
              <Form.Label>{t("translation:form>selectSociety")}</Form.Label>
              <Form.Control
                as="select"
                className="text-capitalize"
                name="society_id"
                required
                onChange={e => this.handleChange(e)}>
                <option key={0} value={NO_SOCIETY}>{t("translation:general>noSociety")}</option>
                {societies.map((el, i) =>
                  <option key={i} value={el.id}>{el.name}</option>
                )}
              </Form.Control>
            </Form.Group>
    }
  };

  render() {
    const clients = this.state.clients ? this.state.clients : [];
    const types = this.props.types ? this.props.types : [];
    const { t } = this.props;
    const { societies } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={e => this.handleSubmit(e)}>
        <Row className="pt-3 pb-4">
        <Col className="col-12" xl={5}>
            <div className="title p-2">
              <h4>{t("translation:modal>users>title")}</h4>
              <p>{t("translation:modal>users>subtitle")}</p>
            </div>
        </Col>
        <Col className="col-12" xl={7}>
        {attributes.map(item => {
          return <Form.Group key={item.id}>
              <Form.Label htmlFor={item.id}>{t(`translation:form>${item.label}`)}</Form.Label>
              <Form.Control onChange={e => this.handleChange(e)} className="rounded-0" {...item} placeholder={`${t("translation:form>enter")} ${t(`translation:form>${item.label}`).toLowerCase()}`}  required/>
            </Form.Group>
        })}
        <Form.Group>
          <Form.Control
            as="select"
            className="text-capitalize"
            name="type_id"
            required
            onChange={e => this.handleChangeType(e)}>
            <option value= "" >{t("translation:form>selectUserType")}</option>
            {types.map((el, i) =>
              <option key={i} value={el.id}>{t(el.name)}</option>
            )}
          </Form.Control>
        </Form.Group>
        {this.showSelectClient(this.state.selectClientShow, clients)}
        {this.showSelectSociety(this.state.selectSocietyShow, societies)}
          <Button variant="btn btn-primary rounded-0 float-right" type="submit">{t("translation:general>create")}</Button>
        </Col>
      </Row>
        </form>
      </React.Fragment>
    );
  }
}

const data = state => ({
  types: state.user.types,
  clients: state.client.clients,
  societies: state.society.societies,
  clientsSocieties: state.clientSociety.clientsSocieties,
  client: state.client.client,
  users: state.user.users,
});

const actions = dispatch => ({
  createUser: (user) => dispatch({type: CREATE_USER, payload: user}),
  getUsers: (filter) => dispatch({type: GET_USERS, filter: filter}),
  getTypes: () => dispatch({type: GET_TYPES}),
  getClients: () => dispatch({type: GET_CLIENTS}),
  getSocieties: () => dispatch({type: GET_SOCIETIES}),
  getClientsSocieties: () => dispatch({type: GET_CLIENTS_SOCIETIES}),
  getClient: (clientId) => dispatch({type: GET_CLIENT, payload: clientId}),
});

export default connect(data, actions)(withTranslation()(CreateUser));

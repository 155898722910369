import React, {Component} from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import '../../../css/app.css'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import dateHelper from  '../../../helpers/date.helper'
import { ATTRIBUTE_TYPE } from '../../../config/constants/components'

class modal extends Component {
  render() {
    if (this.props.task.hasOwnProperty('attributeValues')) {
      const {attributeValues} = this.props.task;
      const subject = attributeValues.find(a => a.type === ATTRIBUTE_TYPE.TEXTFIELD);
      const date = attributeValues.find(a => a.type === ATTRIBUTE_TYPE.ALARM_DATE);
      const content = attributeValues.find(a => a.type === ATTRIBUTE_TYPE.TEXTFIELD_AREA);
      const { t } = this.props;
      return (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.show}
          onHide={this.props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>…{t("translation:task>negotiation>name")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xl="4">
                <p className="mb-0 font-weight-bold">{t(subject.label)}</p>
                <span className="text-muted">{subject.value}</span>
                <p className="mt-2 mb-0 font-weight-bold">{t(date.label)}</p>
                <span className="text-muted">{dateHelper.parseDate(new Date(date.value))}</span>
              </Col>
              <Col xl="8">
                <p className="mb-0 font-weight-bold">{t(content.label)}</p>
                <span className="font-weight-light">{content.value}</span>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )
    }
    return (<React.Fragment/>)
  }
}

const data = state => ({
  stages: state.cause.cause.hasOwnProperty('stages')
    ? state.cause.cause.stages
    : []
});

const actions = dispatch => ({
})

export default connect(data, actions)(withTranslation()(modal))

import {
  FETCH_USERS,
  FETCH_USERS_CLIENT_SOCIETIES,
  FETCH_USER_DATA,
  PUSH_USER,
  TOGGLE_USER_AUTH,
  FETCH_TYPES,
} from "./types";
import {
  REMOVE_USER_AUTH
} from "../../sagas/token/types"

const initial = {
  isLoggedIn: false,
  users: [],
  usersClientSociety: [],
  user: {
    first_name:  ""
  },
  types: [],
};


export default function (state = initial, action) {
  const {type, payload} = action;
  switch (type) {
    case TOGGLE_USER_AUTH:
      return {...state, isLoggedIn: !state.isLoggedIn};
    case FETCH_USERS:
      return {...state, users: payload};
    case FETCH_USERS_CLIENT_SOCIETIES:
      return {...state, usersClientSociety: payload};
    case FETCH_USER_DATA:
      return {...state, user: payload};
    case PUSH_USER:
      return {...state, users: [...state.users, payload ] };
    case FETCH_TYPES:
      return {...state, types: payload};
    case REMOVE_USER_AUTH:
        return {...state, isLoggedIn: false};
    default:
      return state;
  }
}

import {
  FETCH_TITLE,
  FETCH_TASKS,
  FETCH_ACTUAL_TASKS,
  ADD_TASK,
  FETCH_NEGOTIATION,
  FETCH_CONCESSION,
  FETCH_PAYMENT} from "./types";

const initial = {
  actualTasks: [],
  payment: {},
  tasks: [],
  title: {},
  negotiation: {},
  concession: {},
}

export default (state = initial, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TITLE:
      return {...state, title: payload}
    case FETCH_PAYMENT:
      return {...state, payment: payload}
    case FETCH_NEGOTIATION:
      return {...state, negotiation: payload}
    case FETCH_CONCESSION:
      return {...state, concession: payload}
    case FETCH_TASKS:
      return {...state, tasks: payload}
    case FETCH_ACTUAL_TASKS:
      return {...state, actualTasks: payload}
    case ADD_TASK:
      const { title } = state;
      title.tasks.push(payload);
      return {...state, title}
    default:
      return state;
  }
}

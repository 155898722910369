import React, {Component} from 'react'
import {connect} from "react-redux";
import Cookies from 'universal-cookie'
import { Redirect } from 'react-router';
import {Dropdown, Image} from 'react-bootstrap';
import {DROPDOWN} from '../config/constants/topNavBar';
import { withTranslation } from "react-i18next";
import EMPTY_AVATAR from '../assets/images/avatar.svg';

const cookies = new Cookies();

class CustomToggle extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <Image src={this.props.src || EMPTY_AVATAR} onClick={this.handleClick} roundedCircle height={40} width={40}/>
    );
  }
}

class CustomDropdown extends Component {
  state = {
    redirect: false,
    to: '/login',
  };

  logout = async () => {
    console.log('Cokie antigua',cookies.getAll());
    //cookies.addChangeListener(this.onCookieChange.bind(this));
    cookies.addChangeListener(()=>  {
      console.log('Cookie Removida');
      console.log('Cokie nueva1',cookies.getAll());
      window.location.href = "/login";
      cookies.removeChangeListener();
      console.log('Cokie nueva2',cookies.getAll());
    }) 
    cookies.remove('uuid_session', {path: '/'});
}

  clickHandler(to) {
    this.setState({ redirect: true, to })
  }

  RenderRedirect = () => {
    this.setState({ redirect: false })
    return (
      <Redirect to={this.state.to}/>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <Dropdown>
        <Dropdown.Toggle src={this.props.user.avatar} as={CustomToggle} id="dropdown-custom-components"/>
        {(this.state.redirect) ? this.RenderRedirect() : <React.Fragment/>}
        <Dropdown.Menu>
          <Dropdown.Item eventKey="1" disabled>
            {`${this.props.user.first_name} ${this.props.user.last_name}`
            || DROPDOWN.NAME}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.clickHandler('/profile/configuration')} eventKey="3">{t("translation:general>profile")}</Dropdown.Item>
          <Dropdown.Divider/>
          <Dropdown.Item eventKey="4" onClick={this.logout}>{t("translation:general>logout")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
};

const data = state => ({
  user: state.user.user,
});

export default connect(data, null)(withTranslation()(CustomDropdown))

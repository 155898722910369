export const SIDE_BAR = {
  ALERTS: {
    STAGE: 'Alertas de la etapa',
    ALL: 'Todas',
    LOW: 'Bajas',
    MEDIUM: 'Medias',
    HIGH: 'Altas',
  },
  STAGE: {
    NOT_SELECTED: 'No seleccionada',
  },
  SUBSTAGE: {
    NOT_SELECTED: 'No seleccionada',
  },
};

export const TITLES_TYPES = {
  SELL: 'Venta',
  APORTE: 'Aporte',
  LEASE: 'Arriendo',
  MORTGAGE: 'Hipoteca',
  AVIO: 'Avío',
  HERITAGE: 'Herencia',
  AUCTION: 'Remate',
  ADJUDICATION: 'Adjudicación',
  LIFT: 'Alzamientos',
  EXTENSION_LEASE: 'Prorroga Arriendo',
};

import React, {Component} from 'react'
import {connect} from "react-redux";
import {Switch} from 'react-router-dom'
import {NavBar} from '../../components/causes';
import {PrivateRoute} from "../../components/routes";
import Stages from './stages';
import Information from './information';
import Alerts from './alerts';
import Documents from './documents';
import Surveillance from './surveillance';
import Titles from './titles';
import Negotiation from './negotiation';
import {GET_CAUSE} from "../../sagas/cause/types";
import {GET_ALERTS_BY_CAUSE} from "../../sagas/alert/types";
import {GET_TASKS_BY_ID} from "../../sagas/tasks/types";
import {GET_CAUSE_INFO} from "../../sagas/cause/types";
import { GET_REGIONS, GET_PROVINCES, GET_COURTS, GET_COMMUNES } from "../../sagas/region/types"


class Index extends Component {
  state = {
    task: {},
    cause: {},
    stages: [],
  };

  componentWillMount() {
    const { id } = this.props.match.params;
    console.log(id);
    this.props.getCause(id);
    this.props.getCauseInfo(id);
    this.props.getAlerts(id);
    this.props.getRegion();
    this.props.getProvince();
    this.props.getCommune();
  }

  shouldComponentUpdate(nextProps) {
    return (this.props.task !== nextProps.task
      || (this.props.cause !== nextProps.cause
      && this.props.stages !== nextProps.stages));
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.task) !== JSON.stringify(this.state.task)) {
      this.setState({
        task: nextProps.task
      })
    }
    if (JSON.stringify(nextProps.cause) !== JSON.stringify(this.state.cause)) {
      this.setState({
        cause: nextProps.cause
      })
    }
    if (JSON.stringify(nextProps.stages) !== JSON.stringify(this.state.stages)) {
      this.setState({
        stages: nextProps.stages
      })
    }
  }

  getTask() {
    this.props.stages.map(stage => {
      if (stage.state === 3) {
        const index = stage.tasks.length - 1;
        this.props.getTasks(stage.tasks[index].id);
      }
    });
  }

  render() {
    try {
      const { stages } = this.props.cause;
      if (this.props.task.id === undefined && this.props.stages.length !== 0) {
        this.getTask();
      } else if (!stages.find(s => s.id === this.props.task.stage.id) && this.props.task.id === undefined){
        this.getTask();
      }
    } catch (e) {
      console.log(e);
    }
    return (
      <>
        <NavBar {...this.props}/>
        <Switch>
          <PrivateRoute exact path="/cause/:id/stages" component={Stages}/>
          <PrivateRoute exact path="/cause/:id/info" component={Information}/>
          <PrivateRoute exact path="/cause/:id/alerts" component={Alerts}/>
          <PrivateRoute exact path="/cause/:id/documents" component={Documents}/>
          <PrivateRoute exact path="/cause/:id/surveillance" component={Surveillance}/>
          <PrivateRoute exact path="/cause/:id/titles" component={Titles}/>
          <PrivateRoute exact path="/cause/:id/negotiation" component={Negotiation}/>
        </Switch>
      </>
    )
  }
}

const actions = dispatch => ({
  getCause: (id) => dispatch({type: GET_CAUSE, payload: id}),
  getAlerts: (id) => dispatch({type: GET_ALERTS_BY_CAUSE, payload: id}),
  getTasks: (taskId) => dispatch({type: GET_TASKS_BY_ID, payload: taskId}),
  getCauseInfo: (id) => dispatch({type: GET_CAUSE_INFO, payload: id}),
  getRegion: () => dispatch({type: GET_REGIONS}),
  getProvince: () => dispatch({type: GET_PROVINCES}),
  getCommune: () => dispatch({type: GET_COMMUNES}),
});


const data = state => ({
  task: state.task.task,
  cause: state.cause.cause,
  stages: state.cause.cause.hasOwnProperty('stages')
    ? state.cause.cause.stages
    : []
});

export default connect(data, actions)(Index);

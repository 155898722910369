import React, { Component } from 'react'
import {connect} from "react-redux";
import { Container, Row, Col, Button, Card, Form, Badge, } from 'react-bootstrap';
import Input from '../../../components/causes/input.causes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import attributeManager from '../../../helpers/attributeValueManager';
import { withTranslation } from "react-i18next";
import { USER_TYPES } from '../../../config/constants/user';
import { GET_PROJECT, FILL_PROJECT_TASK } from "../../../sagas/project/types";
import { DOCUMENT, PROJECT } from '../../../config/constants/states';
const GENERAL_INFORMATION = 'Información General del Proyecto'
const { CATASTRO } = DOCUMENT;

function InfoCol(props) {
  const { t } = props;
  return (
    <Col key={props.id} className="col-12 mb-2 title" md={4}>
      <span className="mb-2">{t(props.label)}</span>
      <p>{attributeManager(props)}</p>
    </Col>
  )
}

class Information extends Component {
  constructor(props){
    super(props);
    this.state={
      attributeValues: [],
      editState: false,
    }
  }
  componentWillMount() {
    const {id} = this.props.match.params;
    this.props.getProject({id});
  }
  handleChange(e) {
    const it = this.props.project.informationTypes.find(obj => obj.name === GENERAL_INFORMATION);
    const aux = it.checks.find(obj => obj.state === PROJECT.NOT_COMPLETE);
    const attributeValues = aux.attributes;
    const project = this.props.project;
    const {name, value} = e.target;
    const idx = attributeValues.findIndex(e => e.id === name);

    attributeValues[idx].value = value;
    this.setState({
      attributeValues,
      project
    });
  }
  generateTable(files) {
    const { t } = this.props;
    return files.map((item) => {
      return(
        <tr>
          <td className="align-middle text-left">
            <p>{(item.name === "") ? item.label : item.name}</p>
            <span>{(item.name === "") ? "" : item.label}</span>
          </td>
          <td className="align-middle text-left">
            {this.generateState(item.state)}
          </td>
          <td className="align-middle float-right actions">
            <Button variant="link" onClick={() => this.handleUploadClick(item)} className="rounded-0 sm">{t("translation:general>load")}</Button>
          </td>
        </tr>
      )
    })
  }
  generateState(state) {
    const { t } = this.props;
    switch (state) {
      case CATASTRO.ABARCAMIENTO.NUMBER:
        return (
        <Badge className='secondary-custom rounded-0'>
          {t(CATASTRO.ABARCAMIENTO.NAME).toUpperCase()}
        </Badge>
        )
      case CATASTRO.OPOSICION.NUMBER:
        return (
        <Badge className='danger-custom rounded-0'>
          {t(CATASTRO.OPOSICION.NAME).toUpperCase()}
        </Badge>
        )
      case CATASTRO.SUPERPOSICION.NUMBER:
        return (
        <Badge className='info-custom rounded-0'>
          {t(CATASTRO.SUPERPOSICION.NAME).toUpperCase()}
        </Badge>
        )
      case CATASTRO.UPLOADED.NUMBER:
        return (
        <Badge className='success-custom rounded-0'>
          {t(CATASTRO.UPLOADED.NAME).toUpperCase()}
        </Badge>
        )
      case CATASTRO.UPDATE.NUMBER:
        return (
        <Badge className='success-custom rounded-0'>
          {t(CATASTRO.UPDATE.NAME).toUpperCase()}
        </Badge>
        )
      default:
        return (
        <Badge className='warning-custom rounded-0'>
          {t(CATASTRO.EMPTY.NAME).toUpperCase()}
        </Badge>
        )
    }
  }
  generateColInfo() {
    try {
      const it = this.props.project.informationTypes.find(obj => obj.name === GENERAL_INFORMATION);
      return (
        <Form.Row>
          {it.checks.map(cAux => {
            return cAux.attributes.map(c => <Input
              handleChange={e => this.handleChange(e)}
              handleDateChange={(date, id) => this.handleDateChange(date, id)}
              {...c}
              nextTask={null}
              sizeColumn={4}
              errors={[]}
            />
          )})}
        </Form.Row>
      );
    } catch (e) {
      console.log(e);
    }
  }
  infoContainer(){
    try {
      const { t } = this.props;
      const it = this.props.project.informationTypes.find(obj => obj.name === GENERAL_INFORMATION);
      return (
        <Row>
          {
            it.checks.map(cAux => {
            return cAux.attributes.map(c => <InfoCol t={t} {...c}/>)})
          }
        </Row>
        );
    } catch (e) {
      console.log(e);
    }
  };
  updateButton(){
    try {
      const { type } = this.props.user;
      if( type.trim() === USER_TYPES.SUPER_ADMIN || type.trim() === USER_TYPES.ADMIN ){
        const { t } = this.props;
        return (
          <Button
            variant="btn btn-primary rounded-0 float-right mt-3"
            onClick={e => this.handleClick(e)}>
              {t("translation:general>update")}
          </Button>
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
  handleClick(e){
    const it = this.props.project.informationTypes.find(obj => obj.name === GENERAL_INFORMATION);
    const empty = it.checks.find(obj => obj.state === PROJECT.NOT_COMPLETE);
    const obj = {
      project_information_attribute_id: empty.id,
      state: PROJECT.NOT_COMPLETE,
      attributes: this.state.attributeValues,
    };
    this.setState({
      editState: false,
    })
    this.props.fillProjectTask(obj);
  }
  editStateHandler(editState){
    if(editState){
      return(
        <>
        {this.generateColInfo()}
        {this.updateButton()}
        </>

    )
    } else {
      return this.infoContainer()
    }
  };
  showAddAction(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN && !this.state.editState ){
      const{ t } = this.props;
      return (
      <>
      <Button variant='link' onClick={(e) => {this.handleClickEdit(e)}}>
        <FontAwesomeIcon className="fa-sm" icon={['far', 'plus']}/>{t("translation:general>edit")}
      </Button>
      </>
      )
    }
  }
  handleClickEdit(e){
    this.setState({
      editState: true,
    });
  }
  render(){
    const {editState} = this.state;
    const { t } = this.props;
    return (
      <Container className="pb-5 info">
        <Row className="mtn-5">
          <Col className="col-12">
          <Card className="right-content">
            <div className="contain-header d-flex align-items-center title justify-content-between p-4">
              <h5 className="bd-highlight">{t("translation:general>generalInformation")}</h5>
              {this.showAddAction(this.props.user)}
            </div>
            <Container className="table">
              {this.editStateHandler(editState)}
            </Container>
          </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const actions = dispatch => ({
  getProject: (id) => dispatch({type: GET_PROJECT, payload: {id}}),
  fillProjectTask: (obj) => dispatch({type: FILL_PROJECT_TASK, payload: obj}),
});


const data = state => ({
  user: state.user.user,
  project: state.project.project
});

export default connect(data, actions)(withTranslation()(Information));

export const GENERAL = {
  EMPTY: '',
  NAME: 'Nombre',
  ADDRESS: 'Dirección',
  EMAIL: 'Correo electrónico',
  CREATED: 'Creado',
  REPRESENTATIVES: 'Representantes',
  CAUSES_NUMBER: 'Nº de casos',
  TAXPAYER_ID: 'RUC',
  PHONE: 'Teléfono',
  CLIENT: 'Cliente',
  LAST_UPDATE: 'Última modificación',
  FORMAT: 'Formato',
  NUMBER: '#',
  STAGE: 'Etapa',
  STAGE_SUBSTAGE: 'Etapa/Subetapa',
  COMMENTS: 'Comentarios',
  DATE: 'Fecha',
  STATE: 'Estado',
  LOADING: ' Loading...',
};
export const CAUSES = {
  LAWYERS: 'Abogados',
  ROL: 'rol',
  JUDGE: 'juzgado'
};

export const DOCUMENTS = {
  NORMAL: 'Expedientes de causa',
  CATASTRO: 'Vigilancia',
  CREATE_CATASTRO: 'Nuevo Catastro',
  LOADING: ' Loading...',
  UPLOAD: {
    FILE_HERE: 'Sube tus archivos aquí',
    DRAG_AND_DROP: 'Arrastra y suelta o haz click para seleccionar',
    FILE: 'Subir Archivo',
    LOCALLY: 'Cargar archivo desde el Ordenador',
    URL: 'Cargar archivo desde una Url',
  },
  URL: 'Url de archivo',
  NAME: 'Nombre del archivo',
  INSERT: 'Ingrese',
  ERROR: 'ERROR AL SUBIR ARCHIVO!',
  WITH_EXTENSION: 'con extensión',
  EXAMPLE: 'Ejemplo: test.pdf'
}

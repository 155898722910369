import React, { Component } from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Tables from '../../../components/tables';
import Modal from '../../../components/modal';
import CreateCatastro from '../../../components/causes/create.catastro';
import Pagination from '../../../components/pagination';
import { GET_CAUSE_NORMAL_FILES, GET_CAUSE_CATASTRO_FILES } from "../../../sagas/cause/types";
import { GET_FILES_CAUSE  } from "../../../sagas/file/types";
import { GENERAL } from '../../../config/constants/tableTitles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { USER_TYPES } from '../../../config/constants/user';
import Empty from '../../../components/emptyCard';
import { BadgeStates } from '../../../resolver/cause/surveillance';

const PENDING_STATE = 0;

class Surveillance extends Component {
  constructor(props) {
    super(props);
    this.state = { modalShow: false };
    this.handleCatastroPagination = this.handleCatastroPagination.bind(this);
    this.handleNormalPagination = this.handleNormalPagination.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  componentWillMount() {
    const {id} = this.props.match.params;
    const pagination = {
      limit: 5,
      page: 1,
    };
    this.props.getCauseNormalFiles(id, pagination);
    this.props.getCauseCatastroFiles(id, pagination);
  }

  handleClick(file_id) {
    const {id} = this.props.match.params;
    this.props.getFile(id, file_id);
  }

  handleCatastroPagination(page) {
    const {id} = this.props.match.params;
    const pagination = {
      limit: 5,
      page,
    };
    this.props.getCauseCatastroFiles(id, pagination);
  }

  handleNormalPagination(page) {
    const {id} = this.props.match.params;
    const pagination = {
      limit: 5,
      page,
    };
    this.props.getCauseNormalFiles(id, pagination);
  }

  generateCatastroTable = (props) => {
    const files = props.content || [];
    const { t } = this.props;
    return files.map((item) => {
      return(
        <tr>
          <td></td>
          <td className="align-middle text-left">
            <p>{t(item.file_name)}</p>
            <span>{t(item.name)}</span>
          </td>
          <td className="align-middle text-left">
            <p>{item.upload_date}</p>
          </td>
          <td className="align-middle text-left">
            <BadgeStates state={item.state} t={t}/>
          </td>
          <td className="align-middle float-right actions">
            <Button variant="link"
            onClick={(e) => this.handleClick(item.file_id)}
            {...(item.state === PENDING_STATE) ? {disabled: true} : {}}>
              {t("translation:general>show")}
            </Button>
            {(this.props.user.type.includes(USER_TYPES.ADMIN))
              ? <Button variant="link" {...(item.state === PENDING_STATE) ? {disabled: true} : {}}>
                  {t("translation:general>delete")}
                </Button>
              : <React.Fragment></React.Fragment>
              }
          </td>
        </tr>
      )
    })
  }

  modalClose() {
    this.setState({modalShow: false});
  }

  showCreateModal(user) {
    const type = user.type ? user.type.trim() : '';
    if (type === USER_TYPES.SUPER_ADMIN && this.props.causeCatastroFiles.total > 0) {
      const { t } = this.props;
      return (
        <>
          <Button variant='link' onClick={() => this.setState({modalShow: true})}>
            <FontAwesomeIcon className="fa-sm" icon={['far', 'plus']}/>
            {` ${t("translation:general>new")} ${t("translation:general>cadastre")}`}
          </Button>
          <Modal show={this.state.modalShow} onHide={this.modalClose}>
            <CreateCatastro closeModal={this.modalClose}/>
          </Modal>
        </>
      )
    }
  }

  emptyAlertTable = () => {
    const { t } = this.props;
    return (
    <Col className="pl-2" xs="7" md="3">
      <h5>
        {t("translation:emptyTable>cause>surveillance>title")}
      </h5>
      <p className="text-muted">
        {t("translation:emptyTable>cause>surveillance>subtitle")}
      </p>
    </Col>
  )}

  toogleTables = () => {
    const Table = this.generateCatastroTable;
    const EmptyTable = this.emptyAlertTable;
    const catastroFiles = this.props.causeCatastroFiles.data || [];
    if (catastroFiles.length > 0) {
      const { t } = this.props;
      return (
      <Tables titles={[
        GENERAL.EMPTY,
        t("translation:general>name"),
        t("translation:general>date"),
        t("translation:general>name"),
        GENERAL.EMPTY,
        ]}>
        <Table content={catastroFiles}/>
      </Tables>
      )
    }
    return (
      <Empty type="empty-table">
        <EmptyTable/>
      </Empty>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Container className="pb-5 info">
          <Row className="mtn-5">
            <Col className="col-12">
              <Card className="info__general">
                <div className="title d-flex bd-highlight align-items-center justify-content-between p-4">
                  <h5 className="bd-highlight">{t("translation:general>surveillance")}</h5>
                  {this.showCreateModal(this.props.user)}
                </div>
                {this.toogleTables()}
              </Card>
            </Col>
            <Pagination
              {...this.props.causeCatastroFiles}
              handleChange={this.handleCatastroPagination}/>
          </Row>
        </Container>
      </>
    )
  }
}

const actions = dispatch => ({
  getCauseNormalFiles: (id, pagination) => dispatch({type: GET_CAUSE_NORMAL_FILES, payload: {id, pagination}}),
  getCauseCatastroFiles: (id, pagination) => dispatch({type: GET_CAUSE_CATASTRO_FILES, payload: {id, pagination}}),
  getFile: (cause_id, file_id) => dispatch({type: GET_FILES_CAUSE, payload: {cause_id, file_id}})
});

const data = state => ({
  uploadState: state.file.uploadFile,
  causeNormalFiles: state.cause.causeNormalFiles,
  causeCatastroFiles: state.cause.causeCatastroFiles,
  user: state.user.user,
});

export default connect(data, actions)(withTranslation()(Surveillance));

import {takeLatest, call, put} from 'redux-saga/effects'
import {
  GET_USER,
  DELETE_USER,
  GET_USERS,
  GET_TYPES,
  CREATE_USER,
  ASSOCIATE_USER,
  GET_USERS_BY_CLIENT_SOCIETY,
  SET_PERMISSION,
  UPDATE_PROFILE,
  UPDATE_PASSWORD,
  EDIT_USER
} from "./types";
import {
  FETCH_USER_DATA,
  FETCH_USERS,
  FETCH_USERS_CLIENT_SOCIETIES,
  FETCH_TYPES,
  PUSH_USER,
} from "../../stores/user/types";
import {
  FETCH_CAUSE_INFO,
} from "../../stores/cause/types";
import {
  FETCH_CLIENT
} from "../../stores/client/types";
import {
  FETCH_SOCIETY
} from "../../stores/society/types";
import {
  USER_TYPES,
  NO_CLIENT,
} from '../../config/constants/user'
import {
  users,
  delete_user,
  user,
  types,
  create_user,
  set_type,
  set_client_society_user,
  set_client_user,
  set_society_user,
  set_user_permission,
  users_by_client_society,
  update_user_profile,
  update_user_password,
  update_user
} from "../../network/user";
import {
  cause_info,
} from "../../network/causes";
import {
  client
} from "../../network/clients";
import {
  society
} from "../../network/societies";
import { LOGIN_URL } from '../../config/constants/routes';
import { FETCH_ERROR_STATUS } from '../../stores/errors/types';
import { STATES } from '../../config/constants/states';
import Cookies from 'universal-cookie'
import { USER } from '../../config/constants/resources';

const cookies = new Cookies();

function* getUsers(action) {
  try {
    const { filter } = action
    let newFilter = ''
    if(filter)  newFilter = filter;
    const {data} = yield call(users, newFilter);
    yield put({type: FETCH_USERS, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* deleteUser(action){
  try {
    const { payload } = action;
    const { userId } = payload;
    yield call(delete_user, userId);
    let newFilter = ''
    const {data} = yield call(users, newFilter);
    yield put({type: FETCH_USERS, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
  }

}

function* getUsersClientsSocieties(action) {
  const {payload} = action;
  try {
    const {data} = yield call(users_by_client_society,payload);
    yield put({type: FETCH_USERS_CLIENT_SOCIETIES, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* getUser() {
  try {
    const {data} = yield call(user);
    yield put({type: FETCH_USER_DATA, payload: data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* getTypes() {
  try {
    const {data} = yield call(types);
    yield put({type: FETCH_TYPES, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* createUser(action) {
  const {payload} = action;
  try {
    const {data} = yield call(create_user, payload);
    yield call(set_type, data.id,{ typeId: payload.type_id });
    if(payload.type_name === USER_TYPES.CLIENT)
    {
      if(payload.client_society_id){
        yield call(set_client_society_user,{client_society_id: payload.client_society_id, user_client_id: data.id});
      } else if (payload.client_id === NO_CLIENT && payload.society_id !== '0') {
        yield call(set_society_user,{society_id: payload.society_id, user_client_id: data.id});
      } else if (payload.client_id !== NO_CLIENT && payload.society_id === '0')  {
        yield call(set_client_user,{client_id: payload.client_id, user_client_id: data.id});
      }
    }
    yield put({type: PUSH_USER, payload: data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* associateUser(action){
  const {payload} = action;
  if(payload.client_society_id){
    yield call(set_client_society_user,payload);
    const client_response = yield call(client, payload.client_id);
    yield put({type: FETCH_CLIENT, payload: client_response.data.data});
  }
  else if(payload.client_id){
    yield call(set_client_user,payload);
    const client_response = yield call(client, payload.client_id);
    yield put({type: FETCH_CLIENT, payload: client_response.data.data});
  }
  else if(payload.society_id){
    yield call(set_society_user, payload);
    const society_response = yield call(society, payload.society_id);
    yield put({type: FETCH_SOCIETY, payload: society_response.data.data});
  }
}

function* setPermission(action) {
  const {payload} = action;
  try {
    yield call(set_user_permission, payload);
    const response = yield call(cause_info, payload.causeId)
    yield put({type: FETCH_CAUSE_INFO, payload: response.data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* updateProfile(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: STATES.STATUS.WAITING, 
      resource: USER.PROFILE,
    }});
    const { payload } = action;
    const { data } = yield call(update_user_profile, payload);
    yield put({ type: FETCH_USER_DATA, payload: data });
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: STATES.STATUS.SUCCESS,
      hasError: false,
    }});
  } catch (e) {
    console.log(e);
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: e.response.status,
      message: e.response.data,
      hasError: true
    }});
  }
}

function* updateUser(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: STATES.STATUS.WAITING, 
      resource: USER.PROFILE,
    }});
    const { payload } = action;
   /*  if(payload.type_name === USER_TYPES.CLIENT)
    {
      if(payload.client_society_id){
        yield call(set_client_society_user,{client_society_id: payload.client_society_id, user_client_id: data.id});
      } else if (payload.client_id === NO_CLIENT) {
        yield call(set_society_user,{society_id: payload.society_id, user_client_id: data.id});
      } else {
        yield call(set_client_user,{client_id: payload.client_id, user_client_id: data.id});
      }
    } */
    const {data} = yield call(update_user, payload);
    /* let newFilter = ''
    const {data} = yield call(users, newFilter);
    console.log('info',data); */
    yield put({ type: FETCH_USER_DATA, payload: data });
  } catch (e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: e.response.status,
      message: e.response.data,
      hasError: true
    }});
  }
}

function* updatePassword(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: STATES.STATUS.WAITING,
      resource: USER.PASSWORD,
    }});
    const { payload } = action;
    yield call(update_user_password, payload);
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS, hasError: false }});
  } catch (e) {
    console.log(e);
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.response.status, message: e.response.data, hasError: true }});
  }
}


function* watchGetUsers() {
  yield takeLatest(GET_USERS, getUsers)
}

function* watchUpdateUser() {
  yield takeLatest(EDIT_USER, updateUser)
}

function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, deleteUser)
}

function* watchGetUsersClientsSocieties() {
  yield takeLatest(GET_USERS_BY_CLIENT_SOCIETY, getUsersClientsSocieties)
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE, updateProfile)
}

function* watchUpdatePassword() {
  yield takeLatest(UPDATE_PASSWORD, updatePassword)
}

function* watchGetUser() {
  yield takeLatest(GET_USER, getUser)
}

function* watchGetTypes() {
  yield takeLatest(GET_TYPES, getTypes)
}

function* watchCreateUser() {
  yield takeLatest(CREATE_USER, createUser)
}

function* watchAssociateUser() {
  yield takeLatest(ASSOCIATE_USER, associateUser)
}

function* watchSetPermission() {
  yield takeLatest(SET_PERMISSION, setPermission)
}

export default [
  watchDeleteUser(),
  watchGetUsers(),
  watchGetUsersClientsSocieties(),
  watchUpdateProfile(),
  watchUpdatePassword(),
  watchGetUser(),
  watchGetTypes(),
  watchCreateUser(),
  watchAssociateUser(),
  watchSetPermission(),
  watchUpdateUser()
]

import React, {Component} from 'react'
import {connect} from "react-redux";
import { InputGroup, FormControl, Dropdown, DropdownButton, Table, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {TITLES} from '../config/constants/titles'
import { withTranslation } from "react-i18next";
import {SEARCH_CAUSES, SEARCH_CLIENTS, SEARCH_SOCIETIES} from '../sagas/search/types'


function Icon(props) {
  return (props.name === TITLES.CAUSE)
    ? (<FontAwesomeIcon icon={['far', 'archive']} />)
    : (<FontAwesomeIcon icon={['far', 'address-card']} />)
}

class SearchBar extends Component {
  constructor(props){
    super(props);
    const { t } = this.props;
    this.state = {
      displayTable: false,
      name: t("translation:general>cause"),
      search: "",
    };
  }

  handleNameChange(e) {
    this.setState({ name: e.target.name, search: '' });
    this.handleChange(e);
  }

  handleCauseChange(e) {
    const query = [['Cause/Name', e.target.value]];
    this.props.searchCauses(query);
  }

  handleClientChange(e) {
    const query = [['Type/Name', e.target.value]];
    this.props.searchClients(query)
  }

  handleSocietyChange(e) {
    const query = [['Type/Name', e.target.value]];
    this.props.searchSocieties(query)
  }

  handleChange(e) {
    const { t } = this.props;
    switch (this.state.name) {
      case t("translation:general>cause"):
        this.handleCauseChange(e);
        break;
      case t("translation:general>client"):
        this.handleClientChange(e);
        break;
      case t("translation:general>society"):
        this.handleSocietyChange(e);
        break;
      default:
        this.handleCauseChange(e);
    }
    if (e.target.value !== '') this.setState({ displayTable: true });
    else this.setState({ displayTable: false });
  }

  handleClick(item) {
    const { t } = this.props;
    switch (this.state.name) {
      case t("translation:general>cause"):
        window.location.href = `/cause/${item.id}/stages`;
        this.setState({ search: '' });
        break;
      case t("translation:general>client"):
        window.location.href = `/clients/profile/${item.id}`;
        this.setState({ search: '' });
        break;
      case t("translation:general>society"):
        window.location.href = `/societies/profile/${item.id}`;
        this.setState({ search: '' });
        break;
      default:
        window.location.href = `/cause/${item.id}/stages`;
    }
  }

  generateTableContent() {
    const { search } = this.props;
    if(search.data) {
      if (search.data.length > 0) {
        return this.props.search.data.map(item => {
          return (
            <tr className="pointer" onClick={() => this.handleClick(item)}>
              <td className="align-middle">
                <Icon name={this.state.name}/>
              </td>
              <td className="align-middle">
                <p className="mb-0">
                  {item.name}
                </p>
                <span>{item.type || ''}</span>
              </td>
              {(item.client_name)
                ? (
                  <td className="align-middle">
                  <p className="mb-0">
                    {item.client_name}
                  </p>
                  <span>{item.rol}</span>
                </td>
                )
                : null}
            </tr>
          )
        })
      }
      if (this.state.search !== '') {
        const { t } = this.props;
        return (
          <React.Fragment>
          <Container className="container-search">
            <FontAwesomeIcon className="mr-2 mt-4" icon="search"/>
              {t("translation:searchBar>notFound")}
          </Container>
          </React.Fragment>
        )
      }
    }
  }

  table() {
    if (this.state.displayTable) {
      return (
        <Table className="info__customers table-custom" bordered hover variant="light">
          <tbody>
            {this.generateTableContent()}
          </tbody>
        </Table>
      )
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
      <InputGroup className="w-400" onChange={e => this.handleChange(e)}>
        <span className="icon_search"><FontAwesomeIcon icon="search"/></span>
        <FormControl
          placeholder={(this.state.name === t("translation:general>cause"))
                ? `${t("translation:searchBar>search")} ${t("translation:general>cause")}`
                : (this.state.name === t("translation:general>client"))
                ? `${t("translation:searchBar>search")} ${t("translation:general>client")}`
                : `${t("translation:searchBar>search")} ${t("translation:general>society")}`}
          aria-label="Username"
          value={this.state.search}
          onChange={(e) => this.setState({ search: e.target.value })}
          aria-describedby="basic-addon1"
        />

        <DropdownButton
          as={InputGroup.Append}
          className="dropdown-search mr-3"
          variant="primary"
          title={this.state.name}
          id="input-group-dropdown-2"
        >
          <Dropdown.Item name={t("translation:general>cause")} onClick={(e) => this.handleNameChange(e)}>{t("translation:general>cause")}</Dropdown.Item>
          <Dropdown.Item name={t("translation:general>client")} onClick={(e) => this.handleNameChange(e)}>{t("translation:general>client")}</Dropdown.Item>
          <Dropdown.Item name={t("translation:general>society")} onClick={(e) => this.handleNameChange(e)}>{t("translation:general>society")}</Dropdown.Item>
        </DropdownButton>
      {this.table()}
      </InputGroup>
      </React.Fragment>
    )
  }
}

const data = state => ({
  search: state.search.search,
});
const actions = dispatch => ({
  searchCauses: (query) => dispatch({type: SEARCH_CAUSES, payload: query}),
  searchClients: (query) => dispatch({type: SEARCH_CLIENTS, payload: query}),
  searchSocieties: (query) => dispatch({type: SEARCH_SOCIETIES, payload: query}),
});

export default connect(data, actions)(withTranslation()(SearchBar));

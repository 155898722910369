import React, {Component} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Col, Container, Row, Badge, Button, Tab } from 'react-bootstrap'
import { GreatingBar, Tables } from '../../../components/';
import DASHBOARD from '../../../config/constants/dashboard';
import {GET_ALERTS} from "../../../sagas/alert/types";
import { ALERT } from '../../../config/constants/states';
import { withTranslation } from "react-i18next";
import Empty from '../../../components/emptyCard';

const { MEDIUM, HIGH } = DASHBOARD.ALERT_TYPES ;

function generateTable(props){
  const { t } = props;
  const element = props.content.map(item => {
    const stageName = item.stage_name || item.substage_name;
    return (
      <tr>
          <td></td>
          <td scope="row">
            <p>
              {t(item.name)}
            </p>
            <span>{item.client}</span>
          </td>
          <td>
            <p>
              <Link to={`/cause/${item.cause_id}/stages`}>
                {item.cause_name}
              </Link>
            </p>
            <span>{item.rol}</span>
          </td>
          <td className="align-middle">{` ${item.days_left} ${t("translation:general>daysLeft")}`}</td>
          <td className="align-middle">{`${t("translation:general>stage")} ${t(item.stage_name)}`}</td>
          <td className="align-middle">
            <Badge className={`${(item.priority === HIGH)
              ? ALERT.HIGH
              : (item.priority === MEDIUM)
              ? ALERT.MEDIUM
              : ALERT.LOW}-custom rounded-0`}>
              {t(item.priority)}
            </Badge>
          </td>
          <td></td>
        </tr>
    )
  })
  return element;
}

class Alerts extends Component {

  componentWillMount() {
    console.log(4);
    this.props.getAlerts();
  }

  emptyAlertTable = () => {
    const { t } = this.props;
    return (
    <Col className="pl-2" xs="7" md="3">
      <h5>
        {t("translation:emptyTable>alert>title")}
      </h5>
      <p className="text-muted">
        {t("translation:emptyTable>alert>message")}
      </p>
      <Link to="/causes">
        <Button className="rounded-0">{t("translation:general>check")} {t("translation:general>causes")}</Button>
      </Link>
    </Col>
  )}

  toogleTables = () => {
    const Table = generateTable;
    const EmptyTable = this.emptyAlertTable;
    const { t } = this.props;
    if (this.props.alerts.length > 0) {
      const titles = [
        '',
        t("translation:general>alert"),
        t("translation:general>cause"),
        t("translation:general>time"),
        t("translation:general>stage"),
        t("translation:general>priority"),
        '',
      ]
      return (
      <Tables titles={titles}>
        <Table content={this.props.alerts} t={t}/>
      </Tables>
      )
    }
    return (
      <Empty type="empty-table" icon="exclamation-triangle">
        <EmptyTable/>
      </Empty>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <GreatingBar title={t("translation:general>alerts")}/>

        <Container className="pb-5 mtn-5">
          <Row>
            <Col className="col-12">
              {this.toogleTables()}
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}


const data = state => ({
  alerts: state.alert.alerts
});

const actions = dispatch => ({
  getAlerts: () => dispatch({type: GET_ALERTS})
});

export default connect(data, actions)(withTranslation()(Alerts));

import React, {Component} from 'react';
import {connect} from "react-redux";
import {GET_TASKS_BY_ID} from "../../sagas/tasks/types";
import Tables from '../tables';
import { withTranslation } from "react-i18next";
class TaskTable extends Component {
  handleClick(e,id){
      this.props.getTask(id);
  }

  generateRows(tasks){
    const { t } = this.props;
    try{
      return tasks.map((task, index) => {
        let bgGrey = '';
        let cGreen = '';
        if(this.props.task.id === task.id) bgGrey = 'bg-grey'
        if(task.state === 4) cGreen = 'c-green';
      return (
        <tr className={`text-muted pointer ${bgGrey} ${cGreen}`} onClick={(e) => this.handleClick(e,task.id)} key={task.id} id={task.id} >
            <p className='m-2'>{t(task.name)}</p>
        </tr>);
        });
    }
    catch(e){
      console.log(e);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Tables titles={[]}>
          {this.generateRows(this.props.tasks)}
        </Tables>
      </React.Fragment>
    );
  }
}

const actions = dispatch => ({
  getTask: (id) => dispatch({type: GET_TASKS_BY_ID, payload: id})
});

const data = state => ({
  task: state.task.task,
});
export default connect(data, actions)(withTranslation()(TaskTable));

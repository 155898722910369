import React, { Component } from 'react'
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, Row, Card, Button, Table } from 'react-bootstrap';
import { GET_CAUSE } from "../../../sagas/cause/types";
import { GET_TASKS_BY_STAGE } from "../../../sagas/tasks/types";
import { GET_PAYMENT, CREATE_PAYMENT, FILL_PAYMENT, GET_TASKS_ATTRIBUTES } from "../../../sagas/payment/types";
import CustomModal from './modal.payment';
import { STATES, TASK } from '../../../config/constants/states';
import { CHECK_TASK_CAUSES } from '../../../config/constants/components';
import dateHelper from '../../../helpers/date.helper'
import { USER_TYPES } from '../../../config/constants/user';

const { PAYMENT } = CHECK_TASK_CAUSES;

function getDate(task) {
  if (task.attributeValues) {
    const date = task.attributeValues[0].value;
    return dateHelper.parseDate(new Date(date));
  }
  return ''
}

function getDocument(t,task) {
  if (task.files) {
    return task.files[0].name;
  }
  return t("translation:general>notEnteredDocument");
}

function TableContent(props) {
  const { tasks, t } = props;
  let taskTable = [];
  for (let i=0; i < tasks.length; i += 2) {
    const isLast = !(i + 2 <= tasks.length);
    const type = props.user.type.trim();
    taskTable.push(
      <tr>
        <td className="align-middle text-left">
          {(isLast)
            ? (
              <td>
                <p>{t(tasks[i].name)}</p>
              </td>
            )
            : (
              <td>
                <p>{t(tasks[i + 1].name)}</p>
                <span>{t(tasks[i].name)}</span>
              </td>
            )
          }
        </td>
        <td className="align-middle text-left">
          {(isLast)
              ? (
                <td>
                  <p>{getDate(tasks[i])}</p>
                </td>
              )
              : (
                <td>
                  <p>{getDate(tasks[i + 1])}</p>
                  <span>{getDate(tasks[i])}</span>
                </td>
              )
            }
        </td>
        <td className="align-middle text-left">
          {(isLast)
                ? (
                  <td>
                    <p>{getDocument(t,tasks[i])}</p>
                  </td>
                )
                : (
                  <td>
                    <p>{getDocument(t,tasks[i + 1])}</p>
                    <span>{getDocument(t,tasks[i])}</span>
                  </td>
                )
            }
        </td>
        <td className="align-middle text-left">
          {(isLast)
              ? (type === USER_TYPES.SUPER_ADMIN || type === USER_TYPES.ADMIN) ?
              (
                <td>
                  <Button onClick={() => props.modalShowAndCreate(PAYMENT.SECOND_TASK)} variant="link">{t("translation:general>pay")}</Button>
                </td>
              )
              :
              (
                <td>
                  <Button onClick={() => props.modalShow(tasks[i])} variant="link">{t("translation:general>show")}</Button>
                </td>
              )
              : (
                <td>
                  <Row>
                    <Button onClick={() => props.modalShow(tasks[i + 1])} variant="link">{t("translation:general>show")}</Button>
                  </Row>
                  <Row>
                    <Button onClick={() => props.modalShow(tasks[i])} variant="link">{t("translation:general>show")}</Button>
                  </Row>
                </td>
              )
            }
        </td>
      </tr>
    )
  }
  return (taskTable)
}

class Information extends Component {
  state = {
    modalShow: false,
    emptyTask: {},
  }

  deleteEmptyTask = () => {
    const { tasks } = this.props;
    if (tasks.length !== TASK.EMPTY) {
      const lastState = tasks[tasks.length - 1].state;
      const task = (lastState === TASK.EMPTY) ? tasks.pop() : {};
      this.setState({emptyTask: task})
    }
  }

  componentDidMount() {
    this.deleteEmptyTask()
    this.props.getTasks(this.props.payment.id)
    this.props.getPaymentTasks()
  }

  componentDidUpdate(nextProps) {
    if (nextProps.payment !== this.props.payment) {
      this.deleteEmptyTask()
    }
  }

  modalShowAndCreate = (taskName) => {
    if (!this.state.emptyTask.id) {
      console.log(this.props.staticTasks);
      this.props.createTask(
        {
          stage_check_id: this.props.payment.id,
          task_id: this.props.staticTasks.find(item => item.name.includes(taskName)).id,
          state: TASK.EMPTY,
        });
    } else {
      this.props.getPayment(this.state.emptyTask.id);
    }
    this.setState({modalShow: true});
  }

  modalShow = (task) => {
    this.props.getPayment(task.id);
    this.setState({modalShow: true});
  }

  modalClose = () => {
    this.props.getCause(this.props.cause.id);
    this.props.getPaymentTasks()
    this.setState({modalShow: false});
  }

  saveTask = (attributeValues) => {
    const task = {
      ...this.props.taskPayment,
      state: TASK.COMPLETE,
      attributeValues,
    }

    this.props.fillPayment(task);
    if (this.props.status === STATES.STATUS.SUCCESS) this.setState({modalShow: false});
  }

  render() {
    const type = this.props.user.type.trim();
    const { t } = this.props;
    return (
      <Card className="info__customers mt-3">
        <div className="title d-flex bd-highlight align-items-center p-4">
          <h5 className="w-75 bd-highlight">{t("translation:general>proportionalPatent")}</h5>
          {(this.props.tasks.length % 2 === 0
            && (type === USER_TYPES.SUPER_ADMIN || type === USER_TYPES.ADMIN) )
            ? (
            <Button onClick={() => this.modalShowAndCreate(PAYMENT.FIRST_TASK)} className="rounded-0">
              {`${t("translation:general>new")} ${t("translation:general>form")}`}
            </Button>
            )
            : (<React.Fragment/>)}
        </div>
        <CustomModal
          modalShow={this.state.modalShow}
          saveTask={this.saveTask}
          onHide={this.modalClose}/>
        <Container className="table mh-400">
          <Row className="row mt-4">
            <Table>
              <thead>
                <tr>
                  <th>
                    {t("translation:task>name")}
                  </th>
                  <th>
                    {t("translation:general>dates")}
                  </th>
                  <th>
                    {t("translation:general>documents")}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <TableContent
                  tasks={this.props.tasks}
                  modalShowAndCreate={this.modalShowAndCreate}
                  modalShow={this.modalShow}
                  t={t}
                  user={this.props.user}/>
              </tbody>
            </Table>
          </Row>
        </Container>
      </Card>
    )
  }
}

const actions = dispatch => ({
  fillPayment: (task) => dispatch({type: FILL_PAYMENT, payload: task}),
  createTask: (createTask) => dispatch({type: CREATE_PAYMENT, payload: {createTask}}),
  getTasks: (stageId) => dispatch({ type: GET_TASKS_BY_STAGE, payload: stageId }),
  getPayment: (taskId) => dispatch({type: GET_PAYMENT, payload: taskId}),
  getCause: (id) => dispatch({type: GET_CAUSE, payload: id}),
  getPaymentTasks: () => dispatch({type: GET_TASKS_ATTRIBUTES})
});


const data = state => ({
  user: state.user.user,
  cause: state.cause.cause,
  status: state.error.status,
  payment: state.stage.payment,
  staticTasks: state.stage.tasks,
  tasks: state.stage.payment.tasks,
  taskPayment: state.task.taskPayment,
});

export default connect(data, actions)(withTranslation()(Information));

import {takeLatest, call, put, select } from 'redux-saga/effects'
import deleteDependenciedAttribute from './resolvers/deleteDependenciedAttribute'
import {
  post_task,
  tasks,
  list_tasks_by_stage,
  fill_task,
  create_task,
  delete_task,
} from "../../network/tasks";
import { alert } from "../../network/alerts";
import { cause, cause_info } from "../../network/causes";
import {
  GET_TASKS_BY_ID,
  POST_TASK,
  GET_TASKS_BY_STAGE,
  SET_TASK,
  FILL_TASK,
  DELETE_TASK,
  CREATE_AND_FILL_TASK,
  GET_TASKS_FOR_INFO_BY_ID,
  FILL_TITLE_TASK,
  GET_TITLE_TASK_BY_ID,
  SET_TITLE_TASK,
} from "./types";
import {
  FETCH_TASK,
  FETCH_TASK_INFO,
  FETCH_TITLE_TASK,
} from "../../stores/task/types";
import {
  FETCH_TASKS,
  FETCH_ACTUAL_TASKS,
  FETCH_CONCESSION,
  ADD_TASK} from "../../stores/stage/types";
import {
  FETCH_SET_CAUSE,
  FETCH_CAUSE_INFO,
  FETCH_CAUSE,
} from "../../stores/cause/types";
import { FETCH_TITLE, FETCH_PAYMENT, FETCH_NEGOTIATION } from "../../stores/stage/types";
import {FETCH_ALERT_BY_CAUSE} from "../../stores/alert/types";
import { FETCH_ERROR_STATUS } from '../../stores/errors/types';
import { LOGIN_URL } from '../../config/constants/routes';
import { ATTRIBUTE_TYPE } from '../../config/constants/components';
import { STAGE, STATES } from '../../config/constants/states';
import Cookies from 'universal-cookie'
import { GENERAL } from '../../config/constants/resources';

const cookies = new Cookies();

function* getTasksById(action) {
  const {payload} = action;

  try {
    const {data} = yield call(tasks, payload);
    return data.data;
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getTitleTaskById(action) {
  const {payload} = action;
  try {
    const {data} = yield call(tasks, payload);
    yield put({type: FETCH_TITLE_TASK, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getTaskByIdForInfo(action) {
  const {payload} = action;

  try {
    const {data} = yield call(tasks, payload);
    yield put({type: FETCH_TASK_INFO, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getTasksByStage(action) {
  const {payload} = action;
  try {
    const { data } = yield call(list_tasks_by_stage, payload);
    yield put({type: FETCH_TASKS, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* postTask(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DEFAULT }});
    const {payload} = action;
    const {task } = payload;    
    const causePayload = payload.cause;
    task.attributeValues = task.attributeValues.map(a => {
      if (a.value !== "" || a.type === ATTRIBUTE_TYPE.CHECK_UNIQUE_SUBSTAGE) {
          a.state = 1;
      }
      return a;
    })
    const {data} = yield call(post_task, task);
    var actualTasks = [];
    var title = {};
    var payment = {};
    var negotiation = {};
    var concession = {};

    const newCause = yield call(cause, causePayload.id);
    const causeMinInfo = yield call(cause_info, causePayload.id);
    const stages = [];
    const nombres = ['Fin petitorio minero', 'fin de oposición', 'Fin de Oposición', 'Fin de Nulidad','Fin Tramitación', 'Fin de Servidumbre' ,'Fin de procedimiento', 'Fin del petitorio' , 'Fin de petitorio', 'Fin de tramitación'];    
    const actualTask = (!(nombres.includes(task.name))) ? data.data: task;
    if(actualTask.stage.id)
      {
        const stage = newCause.data.data.stages.find(obj => obj.id === actualTask.stage.id);
        actualTasks = stage.tasks;
      } else if (actualTask.substage.id){
        newCause.data.data.stages.forEach(stage =>{
          stage.substages.forEach(substage => {
            if(substage.id === actualTask.substage.id) actualTasks = substage.tasks;
          });
        });
      } 
    newCause.data.data.stages.forEach((item) => {
      if (item.number === STAGE.TITLE) {
        title = item;
      } else if (item.number === STAGE.PAYMENT) {
        payment = item;
      } else if (item.number === STAGE.NEGOTIATION) {
        negotiation = item;
      } else if (item.number === STAGE.CONCESSION) {
        concession = item;
      }  else {
        stages.push(item);
      }
    });
    yield put({ type: FETCH_NEGOTIATION, payload: negotiation });
    yield put({ type: FETCH_PAYMENT, payload: payment });
    yield put({ type: FETCH_CONCESSION, payload: concession });
    yield put({ type: FETCH_TITLE, payload: title });
    yield put({type: FETCH_SET_CAUSE, payload: {...newCause.data.data, stages}});
    yield put({type: FETCH_ACTUAL_TASKS, payload: actualTasks})
    yield put({type: FETCH_CAUSE_INFO, payload: causeMinInfo.data.data})
    yield put({type: FETCH_TASK, payload: actualTask})
    const alertData = yield call(alert, newCause.data.data.id);
    yield put({type: FETCH_ALERT_BY_CAUSE, payload: alertData.data.data});
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
  } catch (e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* setTask(action) {
  console.log('setTask')
  try {
    const { payload } = action;
    yield put({ type: FETCH_TASK, payload });
  } catch(e) {
    console.log(e);
  }
}

function* setTaskTitle(action) {
  console.log('setTaskTitle')
  try {
    const { payload } = action;
    yield put({ type: FETCH_TITLE_TASK, payload });
  } catch(e) {
    console.log(e);
  }
}

function* fillTask(action) {
  console.log('fillTask')
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const task = yield call(fill_task, payload);
    const {data} = yield call(tasks, task.data.data.id);
    const getCause = (state) => state.cause;
    const { cause } = yield select(getCause);
    const causeMinInfo = yield call(cause_info, cause.id);
    yield put({type: FETCH_CAUSE_INFO, payload: causeMinInfo.data.data})
    yield put({type: FETCH_TASK, payload: data.data})
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
    console.log(e);
  }
}

function* fillTitleTask(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const task = yield call(fill_task, payload);
    const {data} = yield call(tasks, task.data.data.id);
    yield put({type: FETCH_TITLE_TASK, payload: data.data})
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
    console.log(e);
  }
}

function* createAndFillTask(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const { task, createTask } = payload;
    const { data } = yield call(create_task, createTask);
    const newTask = data.data;
    const newAttributes = newTask.attributeValues.map(attribute => {
      const { value } = task.attributeValues.find(a => a.order_number === attribute.order_number);
      attribute.value = value;
      attribute.state = (value === '') ? 0 : 1;
      return attribute;
    });
    newTask.attributeValues = newAttributes;
    newTask.name = task.name;
    newTask.description = task.description;
    yield call(fill_task, newTask);
    yield put({type: FETCH_TITLE_TASK, payload: newTask});
    yield put({type: ADD_TASK, payload: newTask});
    const getCause = (state) => state.cause;
    const { cause } = yield select(getCause);
    const causeMinInfo = yield call(cause_info, cause.id);
    yield put({type: FETCH_CAUSE_INFO, payload: causeMinInfo.data.data})
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* deleteTask(action){
   try {
     yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DELETE }});
     const { payload } = action;
     yield call(delete_task,payload.task.id);
     const getCause = (state) => state.cause;
     const storeCause = yield select(getCause);
     var lastTask;
     let lastStage = storeCause.cause.stages.find(stage => stage.state === STAGE.ACTUAL);
     let actualTasks = [];
     if( payload.task.stage.id ) {
       if (lastStage.tasks.length > 1) {
          const index = lastStage.tasks.length - 2;
          lastTask = lastStage.tasks[index];
          actualTasks = lastStage.tasks;
          actualTasks.pop();
        } else {
          const lastStageIndex = storeCause.cause.stages.findIndex(stage => stage.state === STAGE.ACTUAL);
          lastStage = storeCause.cause.stages[lastStageIndex-1];
          const index = lastStage.tasks.length - 1;
          lastTask = lastStage.tasks[index];
          actualTasks = lastStage.tasks;
        }
     }  else if (payload.task.substage.id) {
      const actualSubstage = lastStage.substages.find(
        obj => obj.id === payload.task.substage.id,
      );
      if(actualSubstage.tasks.length > 1) {
        const index = actualSubstage.tasks.length - 2;
        lastTask = actualSubstage.tasks[index];
        actualTasks = actualSubstage.tasks;
        actualTasks.pop();
      } else {
        const index = lastStage.tasks.length - 1;
        lastTask = lastStage.tasks[index];
        actualTasks = lastStage.tasks;
      }
    }
     const taskResponse = yield call(tasks, lastTask.id);
     const lastTaskAux = taskResponse.data.data;
     lastTaskAux.state = 0;
     deleteDependenciedAttribute(lastTaskAux);
     yield call(fill_task, lastTaskAux);
     yield put({type: FETCH_TASK, payload: lastTaskAux});
     const { data } = yield call(cause, payload.causeId);
     const causeAux = data.data;
     var title = {};
     var payment = {};
     var negotiation = {};
     var concession = {};
     const stages = [];
     causeAux.stages.forEach((item) => {
        if (item.number === STAGE.TITLE) {
          title = item;
        }  else if (item.number === STAGE.PAYMENT) {
          payment = item;
        } else if (item.number === STAGE.NEGOTIATION) {
          negotiation = item;
        } else if (item.number === STAGE.CONCESSION) {
          concession = item;
        } else {
          stages.push(item);
        }
     });
     yield put({ type: FETCH_NEGOTIATION, payload: negotiation });
     yield put({ type: FETCH_PAYMENT, payload: payment });
     yield put({ type: FETCH_CONCESSION, payload: concession });
     yield put({ type: FETCH_TITLE, payload: title });
     yield put({ type: FETCH_CAUSE, payload: {...causeAux, stages} });
     const alertData = yield call(alert, causeAux.id);
     yield put({type: FETCH_ACTUAL_TASKS, payload: actualTasks})
     yield put({type: FETCH_ALERT_BY_CAUSE, payload: alertData.data.data});
     yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
   } catch (e) {
     yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }});
     console.log(e);
   }
}

function* getTask(action) {
  const task = yield* getTasksById(action);
  yield put({type: FETCH_TASK, payload: task});
}

function* watchGetTasksById() {
  yield takeLatest(GET_TASKS_BY_ID, getTask)
}

function* watchGetTitleTasksById() {
  yield takeLatest(GET_TITLE_TASK_BY_ID, getTitleTaskById)
}

function* watchPostTask() {
  yield takeLatest(POST_TASK, postTask)
}

function* watchGetTasksByStage() {
  yield takeLatest(GET_TASKS_BY_STAGE, getTasksByStage)
}

function* watchSetTask() {
  yield takeLatest(SET_TASK, setTask)
}

function* watchDeleteTask() {
  yield takeLatest(DELETE_TASK, deleteTask)
}

function* watchSetTitleTask() {
  yield takeLatest(SET_TITLE_TASK, setTaskTitle)
}

function* watchFillTask() {
  yield takeLatest(FILL_TASK, fillTask);
}

function* watchFillTitleTask() {
  yield takeLatest(FILL_TITLE_TASK, fillTitleTask);
}

function* watchCreateAndFillTask() {
  yield takeLatest(CREATE_AND_FILL_TASK, createAndFillTask);
}

function* watchGetTaskByIdForInfo() {
  yield takeLatest(GET_TASKS_FOR_INFO_BY_ID, getTaskByIdForInfo);
}

export default [
  watchGetTasksById(),
  watchPostTask(),
  watchGetTasksByStage(),
  watchSetTask(),
  watchFillTask(),
  watchSetTitleTask(),
  watchFillTitleTask(),
  watchCreateAndFillTask(),
  watchDeleteTask(),
  watchGetTitleTasksById(),
  watchGetTaskByIdForInfo(),
];

import React, { Component } from 'react'
import {connect} from "react-redux";
import { Container, Row, Col, Table, Button, Card, Modal } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import AddConcession from '../../../components/projects/addConcession.project';
import { USER_TYPES } from '../../../config/constants/user';
import { GET_PROJECT } from "../../../sagas/project/types";
import { PROJECT } from '../../../config/constants/states';
import Empty from '../../../components/emptyCard';
import { PROJECTS } from '../../../config/constants/various';
import { withTranslation } from "react-i18next";

class Concessions extends Component {
  constructor(props){
    super(props)
    this.state={
      modalShow: false,
    }
    this.modalClose = this.modalClose.bind(this);
  }

  modalClose(){
    this.setState({ modalShow: false });
  }

  modalShow(){
    this.setState({modalShow: true});
  }

  componentWillMount() {
    console.log(this.props.match.params.id);
    const {id} = this.props.match.params;
    this.props.getProject({id});
  }

  generateTable(concessions){
    return concessions.map((item, index) => {
      if(item.attributes){
        return (
          <tr>
            <td>
              {index+1}
            </td>
            {item.attributes.map(el => {
              return(
                <td className="align-middle text-left">
                  <p>{(el.value !== "") ? el.value : ''}</p>
                </td>
              );
            })}
          </tr>
        );
      }
    })
  }

  showAddAction(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN ){
      const { t } = this.props;
      return (
      <>
      <Button variant='link' onClick={(e) => {this.handleClickAddConcession(e)}}>
        <FontAwesomeIcon className="fa-sm mr-1" icon={['far', 'plus']}/>{t("translation:general>add")}
      </Button>
      </>
      )
    }
  }

  handleClickAddConcession(e){
    this.setState({
      modalShow: true,
    });
  }

  emptyTable = () => {
    const { t } = this.props;
    return (
    <Col className="pl-2" xs="7" md="3">
      <h5>
        {t("translation:emptyTable>projects>concessions>title")}
      </h5>
      <p className="text-muted">
        {t("translation:emptyTable>projects>concessions>subtitle")}
      </p>
      <Button onClick={() => this.modalShow()} className="rounded-0">{t("translation:general>add")} {t("translation:general>concession")}</Button>
    </Col>
  )}

  toogleEmpty = (concessionsFilled) => {
    const EmptyTable = this.emptyTable;
    if (concessionsFilled.length > 0) {
      const { t } = this.props;
      return (
        <Col className="hr-custom">
          <Card className="info__customers mt-4">
            <Table className="mb-3" hover>
              <thead>
                <tr>
                <th className="th-custom" scope="col"> {t("translation:general>#")} </th>
                  <th className="th-custom" width="80%" scope="col">{t("translation:general>name")}</th>
                </tr>
              </thead>
              <tbody>
                {this.generateTable(concessionsFilled)}
              </tbody>
            </Table>
          </Card>
        </Col>
      )
    }
    return (
      <Empty type="empty-table">
        <EmptyTable />
      </Empty>
    )
  }

  render(){
    const it = this.props.project.informationTypes ? this.props.project.informationTypes.find(obj => obj.name === PROJECTS.CONCESSIONS ): null;
    const concessions = it ? it.checks : [];
    const concessionEmpty = concessions.find(obj => obj.state === PROJECT.NOT_COMPLETE);
    const concessionsFilled = concessions.filter(obj => obj.state === PROJECT.COMPLETE);
    const { t } = this.props;
    return (
      <Container className="pb-5 info">
        <Row className="mtn-5">
          <Col className="col-12">
          <Card className="right-content">
            <div className="contain-header d-flex align-items-center title justify-content-between p-4">
              <h5 className="bd-highlight">{t("translation:general>concessions/plants")}</h5>
              {this.showAddAction(this.props.user)}
            </div>
            <Modal show={this.state.modalShow} onHide={this.modalClose}>
              <AddConcession modalClose={this.modalClose} concession={concessionEmpty}/>
            </Modal>
            <Container className="table">
              {this.toogleEmpty(concessionsFilled)}
            </Container>
          </Card>
          </Col>
        </Row>
      </Container>
    );

  }
}

const actions = dispatch => ({
  getProject: (id) => dispatch({type: GET_PROJECT, payload: {id}}),
});


const data = state => ({
  user: state.user.user,
  project: state.project.project
});

export default connect(data, actions)(withTranslation()(Concessions));

import React, { Component } from 'react'
import {connect} from "react-redux";
import { Container, Row, Col, Badge } from 'react-bootstrap';
import CardCol from '../../../components/card';
import Tables from '../../../components/tables';
import DASHBOARD from '../../../config/constants/dashboard';
import Empty from '../../../components/emptyCard';
import {GET_ALERTS_BY_CAUSE} from "../../../sagas/alert/types";
import { withTranslation } from "react-i18next";

const { MEDIUM, HIGH } = DASHBOARD.ALERT_TYPES ;

function generateTable(props) {
  const { t } = props;
  return props.content.map((item, index) => {
    const stageName = item.stage_name || item.substage_name;
    return(
      <tr>
        <td></td>
        <td className="align-middle text-left">
          <p>{index + 1}</p>
        </td>
        <td className="align-middle text-left">
          <p>{t(item.name)}</p>
        </td>
        <td className="align-middle text-left">
          <p>{item.created_at}</p>
        </td>
        <td className="align-middle text-left">
          <p>{`${item.days_left} ${t("translation:general>daysLeft")}`}</p>
        </td>
        <td className="align-middle text-left">
          <p>{t(stageName)}</p>
        </td>
        <td className="align-middle">
          <Badge className={`${(item.priority === HIGH) ? 'danger' :
          (item.priority === MEDIUM) ? 'warning' : 'success'}-custom rounded-0`}
          variant={(item.priority === HIGH) ? 'danger' :
          (item.priority === MEDIUM) ? 'warning' : 'success'}>{t(item.priority)}</Badge>
        </td>
      </tr>
    )
  })
}

class Information extends Component {
  componentDidMount() {
    this.props.getAlerts(this.props.cause.id);
  }

  emptyAlertTable = () => {
    const { t } = this.props;
    return (
    <Col className="pl-2" xs="7" md="3">
      <h5>
        {t("translation:emptyTable>cause>alerts>title")}
      </h5>
      <p className="text-muted">
        {t("translation:emptyTable>cause>alerts>subtitle")}
      </p>
    </Col>
  )}

  toogleTables = () => {
    const Table = generateTable;
    const EmptyTable = this.emptyAlertTable;
    if (this.props.alertByCause.length > 0) {
      const { t } = this.props;
      const titles = [
        '',
        t("translation:general>#"),
        t("translation:general>name"),
        t("translation:general>created"),
        t("translation:general>alert"),
        t("translation:gemeral.stage"),
        t("translation:general>priority"),
      ];
      return (
      <Tables titles={titles}>
        <Table t={t} content={this.props.alertByCause}/>
      </Tables>
      )
    }
    return (
      <Empty type="empty-table">
        <EmptyTable/>
      </Empty>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <Container className="pb-5 info">
        <Row className="mtn-5">
          <Col className="col-12">
            <CardCol className="info__general" title={t("translation:general>reminders")} button="" to="#">
              {this.toogleTables()}
            </CardCol>
          </Col>
        </Row>
      </Container>
    )
  }
}

const data = state => ({
  alertByCause: state.alert.alertByCause,
  cause: state.cause.cause,
});

const actions = dispatch => ({
  getAlerts: (id) => dispatch({type: GET_ALERTS_BY_CAUSE, payload: id}),
})

export default connect(data, actions)(withTranslation()(Information));

import React, {Component} from 'react'
import {Col, Nav, Card} from 'react-bootstrap'
import Alerts from './alerts.causes'
import Tabs from './tabs.cause'
import '../../css/app.css'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";

function getLanguage() {
  return (typeof window !== 'undefined' && window.localStorage.lang) ||
    window.localStorage || 'es';
}

class sideBar extends Component {

  getStage() {
    const { t } = this.props;
     try { 
    var currentStage = this.props.stages.find(stage => {
      return stage.tasks.find(task => {
        return task.id === this.props.task.id;
      }) || stage.substages.find(substage => {
        return substage.tasks.find(task => {
          return task.id === this.props.task.id;
        });
      });
    });
    return currentStage || { name: t("translation:general>stageNotSelected") };
      } catch (error) {
      console.log('Error',error);
      window.location.href = this.props.location.pathname;
    }  
  };

  getSubstage() {
    var currentSubstage = '';
    const { t } = this.props;
    this.props.stages.forEach(stage => {
      stage.substages.forEach(substage => {
        substage.tasks.forEach(task => {
          if(task.id === this.props.task.id) currentSubstage = substage;
        });
      });
    });
    if (currentSubstage.name) {
      return `${t("translation:general>substage")} ${t(currentSubstage.name)}`
    } else {
      return null;
    }
  };

  render() {
    const currentStage = this.getStage();
    const currentSubStage = this.getSubstage();
    const { t } = this.props;
    const stage = (getLanguage() === 'es')
      ? `${t("translation:general>stage")} ${t(currentStage.name)}`
      : `${t(currentStage.name)} ${t("translation:general>stage")}`;
    return (
      <Col xs={12} lg={5} xl={4}>
        <Card className="left-content">
          <Card.Body className="left-content__title p-4">
            <p className="small mt-4">{stage}</p>
            <p className="small mt-1">{t(currentSubStage)}</p>
            <h4>{t(this.props.task.name)}</h4>
          </Card.Body>

          <Alerts {...this.props}/>

          <div className="mt-3 pb-4 left-content__tabs">
            <Nav className="headernav bg-light" fill >
              <Nav.Item as="li">
                <Nav.Link
                  disabled
                  className="text-left nav-link">
                  {t("translation:general>stages")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tabs {...this.props}/>
          </div>
        </Card>
      </Col>
    )
  }
}

const data = state => ({
  task: state.task.task,
  stages: state.cause.cause.hasOwnProperty('stages')
    ? state.cause.cause.stages
    : []
});

const actions = dispatch => ({
})

export default connect(data, actions)(withTranslation()(sideBar))

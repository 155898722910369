import React, {Component} from 'react'
import {Card, Container, Button, Collapse, TabPane } from 'react-bootstrap'
import { withTranslation } from "react-i18next";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {connect} from "react-redux";
import {GET_TASKS_BY_ID} from "../../sagas/tasks/types";
import {CHANGE_ACTUAL_TASKS} from "../../sagas/stage/types";
import {STAGE} from "../../config/constants/states";

const {
  EMPTY_STATE,
  INCOMPLETE_STATE,
  ACTUAL,
  COMPLETE_STATE,
} = STAGE;

function CollapseSubstage(props) {
  const { t } = props;
  const Substages = props.substages.map(substage => {
    return (
        <div key={props.id} className="text-stage">
          <Button variant="link" className="h6" onClick={e => {
            props.updateActualTasks(substage.tasks)
            const lengthTask = substage.tasks.length;
            if (lengthTask) props.getTask(substage.tasks[lengthTask-1].id);}}>{t(substage.name)}</Button>
        </div>
    )
  });
  return (
    <Collapse
      name={props.stage.name}
      id={`collapse_${props.stage.number}`}
      aria-labelledby="headingTwo"
      data-parent="#accordionExample"
      {...(props.state === 3) && {className: 'show'}}>
      <Card.Body>
        <div className="card-body">
        {Substages}
        </div>
      </Card.Body>
    </Collapse>
  )
}

class StageCollapse extends Component {
  changeActualTasks(tasks){
    this.props.changeActualTasks(tasks);
    const lengthTask = tasks.length;
    if (lengthTask) this.props.getTask(tasks[lengthTask-1].id);
  }
  render() {
    const { t } = this.props;
    return (
    <>
      {
        this.props.stages.map((stage, i) => (
          <>
            <div className="ic-stage" onClick={(e) => this.changeActualTasks(stage.tasks)}>
              <Button variant="secondary"
                      data-toggle="collapse"
                      data-target={`#collapse_${stage.number}`}
                      aria-controls={`collapse_${stage.number}`}>
                {
                  (stage.state === COMPLETE_STATE) ? <FontAwesomeIcon
                      className="fa-lg active float-left align-middle"
                      icon="check-circle"/> :
                    (stage.state === INCOMPLETE_STATE || stage.state === ACTUAL) ?
                      <div className="circle-process float-left text-center">{(stage.number + 1)}</div> :
                      <div className="circle-process circle-np float-left text-center">{(stage.number + 1)}</div>
                }


                <div className={`text-stage ml-3 float-left  ${(stage.state === ACTUAL)
                  ? 'd-inline-block' : (stage.state === EMPTY_STATE)
                    ? 'd-inline-block inactive' : ''}`}>
                  <h6 className={(stage.state === COMPLETE_STATE)
                    ? 'success font-weight-bold mb-0 mt-1' : 'mb-0 mt-1'}>{t(stage.name)}</h6>
                </div>


                <FontAwesomeIcon className="fan dark-color float-right mt-1"
                                 icon={`chevron-${(stage.state === ACTUAL) ? 'up' : 'down'}`}/>


              </Button>
            </div>
          <CollapseSubstage t={t} updateActualTasks={tasks => this.props.changeActualTasks(tasks)} key={stage.id} substages={stage.substages}
                        stage={stage} state={stage.state} index={i} length={this.props.stages.length} getTask={id => this.props.getTask(id)}/>
        </>
        ))
      }
    </>
  )}
}

class SideBar extends Component {
  render() {
    const { t } = this.props;
    return (
      <TabPane active className="show mt-4" id="stages" role="tabpanel" aria-labelledby="stages-tab">
        <div className="accordion" id="accordionExample">
          <Container id="headingOne">
            <div className="line">
              <StageCollapse
                t={t}
                key="stage-collapse"
                changeActualTasks={tasks => this.props.changeActualTasks(tasks)}
                getTask={id => this.props.getTask(id)}
                stages={this.props.stages}/>
            </div>
          </Container>
        </div>
      </TabPane>
    )
  }
}

const data = state => ({
  cause: state.cause.cause,
  task: state.task.task,
  stages: state.cause.cause.hasOwnProperty('stages')
    ? state.cause.cause.stages
    : []
});

const actions = dispatch => ({
  getTask: (id) => dispatch({type: GET_TASKS_BY_ID, payload: id}),
  changeActualTasks: (payload) => dispatch({type: CHANGE_ACTUAL_TASKS, payload: payload}),
});
export default connect(data, actions)(withTranslation()(SideBar))

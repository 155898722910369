import React, {Component} from 'react'
import Check from '../desissionCheck'
import CheckTask from './check.task.causes'
import CheckUnique from './check.unique'
import CheckUniqueSubstage from './check.unique.substage'
import Input from '../inputBox'
import Select from '../select'
import SelectTask from './select.task.causes'
import SelectResource from './select.resource.causes'
import UploadFile from '../uploadFile'
import DatePicker from './datepicker'
import TextfieldArea from '../textfield.area'
import { ATTRIBUTE_TYPE } from '../../config/constants/components'


export default class input extends Component {

  typeToShow() {
    switch (this.props.type) {
      case(ATTRIBUTE_TYPE.SELECT):
        return (<Select
          key={`${ATTRIBUTE_TYPE.SELECT}-${this.props.id}`}
          {...this.props}
          handleChange={this.props.handleChange}
          errors={this.props.errors}/>)

      case(ATTRIBUTE_TYPE.SELECT_RESOURCE_MININFO):
      case(ATTRIBUTE_TYPE.SELECT_RESOURCE_INFO):
      case(ATTRIBUTE_TYPE.SELECT_RESOURCE):
        return (<SelectResource
          key={`${ATTRIBUTE_TYPE.SELECT}-${this.props.id}`}
          {...this.props}
          handleChange={this.props.handleChange}
          errors={this.props.errors}/>)

      case(ATTRIBUTE_TYPE.SELECT_TASK):
        return (<SelectTask
          key={`${ATTRIBUTE_TYPE.SELECT}-${this.props.id}`}
          {...this.props}
          handleChange={this.props.handleChange}
          errors={this.props.errors}/>)

      case(ATTRIBUTE_TYPE.DATEFIELD):
      case(ATTRIBUTE_TYPE.DATE):
        return (<DatePicker
          key={`${ATTRIBUTE_TYPE.DATE}-${this.props.id}`}
          {...this.props}
          handleChange={this.props.handleDateChange}
          errors={this.props.errors}/>)

      case(ATTRIBUTE_TYPE.ALARM_DATE_INFO):
      case(ATTRIBUTE_TYPE.ALARM_DATE):
        return (<DatePicker
          key={`${ATTRIBUTE_TYPE.DATE}-${this.props.id}`}
          {...this.props}
          handleChange={this.props.handleDateChange}
          errors={this.props.errors}/>)

      case(ATTRIBUTE_TYPE.CHECK_TASK):
          return (<CheckTask
            key={`${ATTRIBUTE_TYPE.CHECK_TASK}-${this.props.id}`}
            {...this.props}
            content={["Si","No"]}
            errors={this.props.errors}/>)
      case(ATTRIBUTE_TYPE.CHECK_UNIQUE_SUBSTAGE):
          return (<CheckUniqueSubstage
            key={`${ATTRIBUTE_TYPE.CHECK_UNIQUE}-${this.props.id}`}
            {...this.props}
            errors={this.props.errors}/>)
      case(ATTRIBUTE_TYPE.CHECK_UNIQUE):
          return (<CheckUnique
            key={`${ATTRIBUTE_TYPE.CHECK_UNIQUE}-${this.props.id}`}
            {...this.props}
            errors={this.props.errors}/>)

      case(ATTRIBUTE_TYPE.CHECK):
        return (<Check
          key={`${ATTRIBUTE_TYPE.CHECK}-${this.props.id}`}
          {...this.props}
          content={["Si","No"]}
          handleChange={this.props.handleChange}
          errors={this.props.errors}/>)

      case(ATTRIBUTE_TYPE.FILE):
        return (<UploadFile
          key={`${ATTRIBUTE_TYPE.FILE}-${this.props.id}`}
          {...this.props}
          errors={this.props.errors}/>)
      case(ATTRIBUTE_TYPE.TEXTFIELD_AREA):
        return (
          <TextfieldArea
            key={`${ATTRIBUTE_TYPE.INPUT}-${this.props.id}`}
            {...this.props}
            handleChange={this.props.handleChange}
            errors={this.props.errors}/>)
      default:
        return (
          <Input
            key={`${ATTRIBUTE_TYPE.INPUT}-${this.props.id}`}
            {...this.props}
            handleChange={this.props.handleChange}
            errors={this.props.errors}/>
        )
    }
  }

  render() {
    return (
      <>
        {this.typeToShow()}
      </>
    )
  }
}

import axios from './config';

export const clients = async (query = '') => await axios.get('/b/p/clients' + query,
  {
    headers: {
      service: "CLIENT"
    }
  });
  


export const client = async (clientId) => await axios.get(`/b/p/clients/${clientId}`,
  {
    headers: {
      service: "CLIENT"
    }
  });


export const create_client = async (client) => await axios.post('/b/p/clients', client,
  {
    headers: {
      service: "CLIENT"
    }
  });

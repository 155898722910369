import { ATTRIBUTE_TYPE } from '../config/constants/components';
import dateHelper from './date.helper';
const {
  SELECT,
  SELECT_MININFO,
  CHECK_UNIQUE,
  CHECK_UNIQUE_SUBSTAGE,
  DATE,
  DATE_INFO,
  ALARM_DATE,
  ALARM_DATE_INFO,
} = ATTRIBUTE_TYPE;

const { parseDate } = dateHelper;

export default (attribute, communes) => {
  const { type } = attribute;
  let value = '';
  const emptyValue = '-';
  switch (type) {
    case CHECK_UNIQUE_SUBSTAGE:
    case CHECK_UNIQUE:
      value = attribute.value === 'checked' ? 'No' : 'Si';
      break;
    case ALARM_DATE_INFO:
    case ALARM_DATE:
    case DATE_INFO:
    case DATE:
      if(attribute.value){
        value = parseDate(new Date(attribute.value));
      } else {
        value = attribute.value;
      }
      break;
    case SELECT:
    case SELECT_MININFO:
      if(attribute.value){
        const aux = attribute.options.find(obj => obj.id === attribute.value);
        value = aux.name;
      } else {
        value = attribute.value;
      }
      break;
    default:
        value = attribute.value;
  }

  return value || emptyValue;
}

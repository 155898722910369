import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { GET_USERS, DELETE_USER, GET_TYPES, EDIT_USER} from "../../sagas/user/types";
import { Col, Button, Form, Container, Row, ButtonToolbar, Modal as ModalB, Card, Badge } from 'react-bootstrap';
import { GET_CLIENTS, GET_CLIENT } from "../../sagas/client/types";
import { GET_SOCIETIES } from "../../sagas/society/types";
import { GET_CLIENTS_SOCIETIES } from "../../sagas/clientSociety/types";
import { GreatingBar, Tables, Modal as ModalCustom } from '../../components/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateUser from "../../components/users/create.user";
import filterQueryMaker from '../../helpers/filterQueryMaker.helper';
import '../../css/app.css';
import { GENERAL } from '../../config/constants/tableTitles';
import { USERS } from '../../config/constants/filter';
import { Link } from 'react-router-dom';
import {
  USER_TYPES,
  NO_CLIENT,
  NO_SOCIETY,
} from "../../config/constants/user"

import {
  USER_FIRST_NAME,
  USER_LAST_NAME,
  EMAIL,
  PHONE,
  PASSWORD,
} from "../../config/constants/formAttributes";

const attributes = [
  USER_FIRST_NAME,
  USER_LAST_NAME,
  EMAIL,
  PHONE
]
class Users extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: '',
      idUserEdit: '',
      modalShow: false,
      modalShowDelete: false,
      itemToDelete: {},
      selectClientShow: false,
      selectSocietyShow: false,
      itemAlert: {},
      itemEdit: {},
      userModal: false,
      userEditModal: false,
      modalShowAlert: false,
      users: [],
      user: {},
      clients: [],
      client: {},
      societies: [],
    };
    this.modalClose = this.modalClose.bind(this);
    this.userModal = this.userModal.bind(this);
    this.closeUserModal = this.closeUserModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.notificateAlert = this.notificateAlert.bind(this);
  }

  modalClose(){
    this.state.selectClientShow = false;
    this.state.selectSocietyShow = false;
    this.setState({ modalShow: false, modalShowDelete: false, userEditModal: false});
  }
  
  openModal(){
    this.setState({modalShowAlert: true});
  }

  closeModal(){
    this.setState({modalShowAlert: false});
  }

  notificateAlert(item){
    this.setState({itemAlert: item});
  }

  alertModal = () => {
    const { user, t } = this.props;
    const item = this.state.itemAlert;
    if(item){
      return (
        <ModalB show={this.state.modalShowAlert}>
          <ModalB.Header>
            <ModalB.Title>{t("translation:modal>createUser>alert")}</ModalB.Title>
          </ModalB.Header>
          <ModalB.Body>
            {t("translation:modal>createUser>email")}
            <b>
              {item.email}
            </b>
            {t("translation:modal>createUser>alertEmail")}
          </ModalB.Body>
        </ModalB>
      )
    }
  }


  componentWillMount(){
    this.props.getTypes();
    this.props.getClients();
    this.props.getSocieties();
    this.props.getClientsSocieties();
    this.setState({societies: this.props.societies});
  }

  componentDidMount(){
    const type = this.props.user.type ? this.props.user.type.trim() : '';
    let filterQuery = null;
    if(type === USER_TYPES.CLIENT) {
      const filterParams = [
        [USERS.TYPE_NAME, USER_TYPES.SUPER_ADMIN],
        [USERS.TYPE_NAME, USER_TYPES.ADMIN]
      ];
      filterQuery = filterQueryMaker(filterParams);
      this.props.getUsers(filterQuery);
    }
    this.props.getUsers(filterQuery);
  }
  componentDidUpdate(prevProps){
    if(prevProps.user !== this.props.user && this.props.user.type ){
      const type = this.props.user.type.trim();
      let filterQuery = null;
      if (type === USER_TYPES.CLIENT) {
        const filterParams = [
          [USERS.TYPE_NAME, USER_TYPES.SUPER_ADMIN],
          [USERS.TYPE_NAME, USER_TYPES.ADMIN]
        ];
        filterQuery = filterQueryMaker(filterParams);
      }
      this.props.getUsers(filterQuery);
    }
    if(prevProps.users !== this.props.users){
      this.setState({users: this.props.users})
    }
    if(prevProps.client !== this.props.client && this.props.client ){
      this.setState({client: this.props.client, societies: this.props.client.societies});
    }
  }

  generateTable(content){
    const { user, t } = this.props;
    const type = user.type ? user.type.trim() : '';
    const element = content.map(item => {
      return (
        <tr key={item.id}>
          <td></td>
          <td>
            <p>{`${item.first_name} ${item.last_name}`}</p><span>{t(item.type)}</span>
          </td>
          <td></td>
          <td></td>
          <td className="align-middle">{item.createdAt}</td>
          <td></td>
          <td></td>
          <td className="align-middle">{item.email}</td>  
          <td className="align-middle actions">
            <Link className="nav-link text-center p-0" user={item} onClick={() => this.handleClick(item)}>{t("translation:general>show")}</Link>
            {this.showEditAction(type, item, user)}
            {this.deleteButton(type, item, user)}
          </td>
        </tr>
      )
    })
    return element;
  } 

  deleteButton(type, item, user){
    if( type === USER_TYPES.SUPER_ADMIN && item.id !== user.id ){
      const { t } = this.props;
      return (
        <React.Fragment>
          <Button
            variant= "link"
            className="p-0 w-100 btn-link-warning text-center"
            onClick={() => this.setState({modalShowDelete: true, itemToDelete: item})}>
              {t("translation:general>delete")}
          </Button>

        </React.Fragment>
      )
    } else {
      return (<React.Fragment></React.Fragment>);
    }
  }

  deleteModal = () => {
    const { user, t } = this.props;
    const item = this.state.itemToDelete;
    if(item) {
      return (
        <ModalB show={this.state.modalShowDelete} onHide={this.modalClose}>
          <ModalB.Header closeButton>
            <ModalB.Title>{t("translation:modal>deleteUser>title")}</ModalB.Title>
          </ModalB.Header>
          <ModalB.Body>{t("translation:modal>deleteUser>subtitle1")} <b>{item.first_name} {item.last_name} </b>{t("translation:modal>deleteUser>subtitle2")}</ModalB.Body>
          <ModalB.Footer>
            <Button className="button" variant="outline-primary rounded-0" onClick={e => this.modalClose()}>
              {t("translation:general>cancel")}
            </Button>
            <Button
              variant="danger rounded-0"
              onClick={e => {
              this.handleDelete(e,item);
              this.modalClose();
            }}>
              {t("translation:general>delete")}
            </Button>
          </ModalB.Footer>
        </ModalB>
      )
    }
    else return (<React.Fragment></React.Fragment>)
  }

  handleDelete(e,item) {
    this.props.deleteUser(item.id);
  }

  showEditAction(type, item, user){
    if( type === USER_TYPES.SUPER_ADMIN && item.id !== user.id ){
      const { t } = this.props;
      return (
        <React.Fragment>
          <Button
            variant= "link"
            className="p-0 w-100 btn-link text-center"
            onClick={() => this.setState({userEditModal: true, itemEdit: item})}>
              {t("translation:general>edit")}
          </Button>

        </React.Fragment>
      )
    } else {
      return (<React.Fragment></React.Fragment>);
    }
  }

  showCreateModal(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN ){
      const { t } = this.props;
      return (
        <>
          <ButtonToolbar className="button float-right">
            <Button variant="primary" onClick={() => this.setState({ modalShow: true })}>
              {t("translation:general>create")} {t("translation:general>user").toLowerCase()} <FontAwesomeIcon className="fa-sm" icon={['far', 'plus']}/>
            </Button>
          </ButtonToolbar>
          <ModalCustom show={this.state.modalShow} onHide={this.modalClose}>
            <CreateUser closeModal={this.modalClose} openAlertModal={this.openModal} closeAlertModal={this.closeModal} itemUser={this.notificateAlert}/>
          </ModalCustom>
        </>
      );
    }
  }

  handleClick(user) {
    this.setState({ user, userModal: true});
  }

  information(props) {
    let newValue = props.value;
    if (newValue === '') newValue = '-'
    return (
      <div className="title p-2">
        <p>{props.title}</p>
        <h6>{newValue}</h6>
      </div>
    )
  }

  userModal() {
    const Information = this.information;
    const { t } = this.props;
    return (
      <ModalB show={this.state.userModal} onHide={this.closeUserModal}
        size="sm"
        dialogClassName="modal-40w"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <ModalB.Header closeButton>
          <h5>{t("translation:modal>userDetail>title")}</h5>
        </ModalB.Header>
        <Card className="right-content">
          <Container>
          <Row className="pt-2 pb-4">
            <Col xl={12}>
                <div className="title p-2">
                  <h4>{`${this.state.user.first_name} ${this.state.user.last_name}`}</h4>
                  <Badge className={this.handleType()}>{this.state.user.type}</Badge>
                </div>
                <Information title={t("translation:modal>userDetail>userType")} value={this.state.user.type}/>
                <Information title={t("translation:general>phone")} value={this.state.user.phone}/>
                <Information title={t("translation:general>email")} value={this.state.user.email}/>
            </Col>
          </Row>
          </Container>
        </Card>
      </ModalB>
    )
  }

  handleChange(e,item) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleChangeType(e){
    this.handleChange(e);
    const clientType = this.props.types.find(obj => obj.name === USER_TYPES.CLIENT);
    if(e.target.value === clientType.id) {
      this.setState({
        selectClientShow: true,
        selectSocietyShow: true,
        clients: this.props.clients,
        client: {},
        societies: this.props.societies,
      });
    } else {
      this.setState({
        selectClientShow: false,
        selectSocietyShow: false,
        clients: [],
        client: {},
        societies: [],
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    let filterQuery = null;
    const data = new FormData(e.target);
    const obj = Object.fromEntries(data);
   /*  const clientType = this.props.types.find(objAux => objAux.id === obj.type_id);
    if(clientType) obj.type_name = clientType.name;
    if(obj.type_name === USER_TYPES.CLIENT){
      if(obj.client_id !== NO_CLIENT && obj.society_id !== NO_SOCIETY){
        const cs_id = this.props.client.societies.find(s => (s.id === obj.society_id));
        obj.client_society_id = cs_id.client_society_id;
      }
    } */
    if(this.state.email === obj.email){
      delete obj.email;
    }
    obj.id = this.state.idUserEdit;
    this.props.updateUser(obj);
    this.props.getUsers(filterQuery);
    //window.location.href = `/users`;
    this.modalClose();
  }

  showSelectClient(clientShow,clients){
    if(clientShow){
      const { t } = this.props;
      return  <Form.Group>
                <Form.Label>{t("translation:form>selectClient")}</Form.Label>
                <Form.Control
                  as="select"
                  className="text-capitalize"
                  name="client_id"
                  required
                  onChange={e => this.handleChangeClient(e)}>
                  <option key={0} value={NO_CLIENT}>{t("translation:general>noClient")}</option>
                    {clients.map((el, i) =>
                      <option key={i} value={el.id}>{el.name}</option>
                    )}
                </Form.Control>
                </Form.Group>
    }
  };
  showSelectSociety(societyShow,societies){
    if(societyShow){
      const { t } = this.props;
      return <Form.Group>
              <Form.Label>{t("translation:form>selectSociety")}</Form.Label>
              <Form.Control
                as="select"
                className="text-capitalize"
                name="society_id"
                required
                onChange={e => this.handleChange(e)}>
                <option key={0} value={NO_SOCIETY}>{t("translation:general>noSociety")}</option>
                {societies.map((el, i) =>
                  <option key={i} value={el.id}>{el.name}</option>
                )}
              </Form.Control>
            </Form.Group>
    }
  };

  handleChangeClient(e) {
    this.handleChange(e);
    this.setState({client: {}, societies: this.props.societies});
    if(e.target.value !== NO_CLIENT) this.props.getClient(e.target.value);
  }

  editModal = () => {
    const { user, error ,t } = this.props;
    const itemEdit = this.state.itemEdit;
    const types = this.props.types ? this.props.types : [];
    const clients = this.state.clients ? this.state.clients : [];
    const { societies } = this.state;
    if(itemEdit) {
      this.state.email = itemEdit.email;
      this.state.idUserEdit = itemEdit.id;
      return (
        <React.Fragment>
        <ModalB show={this.state.userEditModal} onHide={this.modalClose}
        size="sm"
        dialogClassName="modal-40w"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <form onSubmit={e => this.handleSubmit(e)}>
          <ModalB.Header closeButton>
            <ModalB.Title>{t("translation:modal>editUser>title")}</ModalB.Title>
          </ModalB.Header>
          <Card className="right-content">
          <Container>
          {attributes.map(item => {
          return <Form.Group key={item.id}>
              <Form.Label htmlFor={item.id}>{t(`translation:form>${item.label}`)}</Form.Label>
              <Form.Control  onChange={e => this.handleChange(e)} className="rounded-0" {...item} placeholder={`${t("translation:form>enter")} ${t(`translation:form>${item.label}`).toLowerCase()}`} defaultValue={itemEdit[item.name]} required/>
            </Form.Group>
        })}
                <Form.Group>
{/*           <Form.Label>{t("translation:form>selectUserType")}</Form.Label>
         <Form.Control
            as="select"
            className="text-capitalize"
            name="type_id"
            required
            onChange={e => this.handleChangeType(e)}>
            {types.map((el, i) =>
              <option selected={el.name === itemEdit.type} key={i} value={el.id}>{t(el.name)}</option>
            )}
          </Form.Control>  */}
        </Form.Group>
{/*         {this.showSelectClient(this.state.selectClientShow, clients)}
        {this.showSelectSociety(this.state.selectSocietyShow, societies)} */}
          </Container>
        </Card>
          <ModalB.Footer>
            <Button className="button" variant="outline-primary rounded-0" onClick={e => this.modalClose()}>
              {t("translation:general>cancel")}
            </Button>
            <Button variant="btn btn-primary rounded-0 float-right" type="submit">{t("translation:general>save")}</Button>
          </ModalB.Footer>
        </form>
        </ModalB>
      </React.Fragment>
      )
    }
    else return (<React.Fragment></React.Fragment>)
  }

  handleConfigChange = (e) => {
    const { userConfig } = this.state;
    const {name, value} = e.target;
    userConfig[name] = value;
    this.setState({
      ...userConfig,
    })
  }
  handleType() {
    switch (this.state.user.type) {
      case USER_TYPES.ADMIN:
        return 'secondary-custom rounded-0';
      case USER_TYPES.SUPER_ADMIN:
        return 'success-custom rounded-0';
      case USER_TYPES.VISUALIZER:
        return 'warning-custom rounded-0';
      default:
        return 'danger-custom rounded-0';
    }
  }
  closeUserModal() {
    this.setState({ userModal: false });
  }

  render() {
    const users = this.state.users || [];
    const { t } = this.props;
    const UserModal = this.userModal;
    const DeleteModal = this.deleteModal;
    const EditModal = this.editModal;
    const AlertModal = this.alertModal;
    const titles = [
      GENERAL.EMPTY,
      t("translation:general>name"),
      GENERAL.EMPTY,
      GENERAL.EMPTY,
      t("translation:general>created"),
      GENERAL.EMPTY,
      GENERAL.EMPTY,
      t("translation:general>email"),
      GENERAL.EMPTY,
    ];
    return (
      <>
        <GreatingBar title={t("translation:general>users")}>
          {this.showCreateModal(this.props.user)}
        </GreatingBar>
        <Container className="pb-5 mtn-5">
          <Row>
            <Col className="col-12">
              <Tables titles={titles}>
                {this.generateTable(users)}
              </Tables>
            </Col>
          </Row>
          <UserModal/>
          <EditModal></EditModal>
          <DeleteModal></DeleteModal>
          <AlertModal></AlertModal>
        </Container>
      </>
    )
  }
}

const data = state => ({
  types: state.user.types,
  users: state.user.users,
  user: state.user.user,
  clients: state.client.clients,
  societies: state.society.societies,
  clientsSocieties: state.clientSociety.clientsSocieties,
  client: state.client.client,
});

const actions = dispatch => ({
  getUsers: (filter) => dispatch({type: GET_USERS, filter: filter}),
  getTypes: () => dispatch({type: GET_TYPES}),
  getClients: () => dispatch({type: GET_CLIENTS}),
  getSocieties: () => dispatch({type: GET_SOCIETIES}),
  getClientsSocieties: () => dispatch({type: GET_CLIENTS_SOCIETIES}),
  getClient: (clientId) => dispatch({type: GET_CLIENT, payload: clientId}),
  deleteUser: (userId) => dispatch({type: DELETE_USER, payload: {userId}}),
  updateUser: (user) => dispatch({type: EDIT_USER, payload: user}),
});

export default connect(data, actions)(withTranslation()(Users));

import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Col, Container, Row, Button, ButtonToolbar, Modal as ModalB } from 'react-bootstrap';
import { GreatingBar, Tables, Avatar, Modal } from '../../components/';
import Input from '../../components/causes/input.causes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_CAUSES, DELETE_CAUSE } from "../../sagas/cause/types";
import Pagination from '../../components/pagination';
import CreateCauses from "../../components/causes/create.causes";
import Empty from '../../components/emptyCard';
import { GENERAL } from '../../config/constants/tableTitles';
import { USER_TYPES } from '../../config/constants/user';
import { GET_USERS } from '../../sagas/user/types';

const FIRST_PAGE = 1;

class Causes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      modalShowDelete: false,
      itemToDelete: {},
    };
    this.modalClose = this.modalClose.bind(this);
  }
  componentWillMount() {
    this.props.getUsers();
    const {id} = this.props.match.params;
    const pagination = {
      limit: 50,
      page: 1,
    };
    this.props.getCauses(pagination);
  }

  modalClose(){
    this.setState({ modalShow: false, modalShowDelete: false });
    this.handleNormalPagination(FIRST_PAGE);
  }

  generateAttributes(attributes) {
    return attributes.map((item, index) => {
      return (
        <Input key={item.id} {...item} />
      )
    })
  }
  handleDelete(e,causeId) {
    this.props.deleteCause(causeId);
    const pagination = {
      limit: 50,
      page: 1,
    };
    setTimeout(() => {
      this.props.getCauses(pagination);
    }, 200) 
    
  }

  deleteModal = () => {
    const { t } = this.props;
    const item = this.state.itemToDelete;
    if(item) {
      return (
        <ModalB show={this.state.modalShowDelete} onHide={this.modalClose}>
          <ModalB.Header closeButton>
            <ModalB.Title>{t("translation:modal>deleteCause>title")}</ModalB.Title>
          </ModalB.Header>
          <ModalB.Body>{t("translation:modal>deleteCause>subtitle1")} <b>{item.name} {t("translation:modal>deleteCause>subtitle2")}</b></ModalB.Body>
          <ModalB.Footer>
            <Button className="button" variant="outline-primary rounded-0" onClick={e => this.modalClose()}>
              {t("translation:general>cancel")}
            </Button>
            <Button
              variant="danger rounded-0"
              onClick={e => {
              this.handleDelete(e, item.id)
              this.modalClose();
            }}>
              {t("translation:general>delete")}
            </Button>
          </ModalB.Footer>
        </ModalB>
      )
    }
    else return (<React.Fragment></React.Fragment>)
  }

  deleteButton(type, item){
    if( type === USER_TYPES.SUPER_ADMIN ){
      const { t } = this.props;
      return (
        <React.Fragment>
          <Button
            variant= "link"
            className="p-0 w-100 btn-link-warning text-center"
            onClick={() => this.setState({modalShowDelete: true, itemToDelete: item})}>
              {t("translation:general>delete")}
          </Button>
        </React.Fragment>
      )
    } else {
      return (<React.Fragment></React.Fragment>);
    }
  }
  generateTable = (props) => {
    const { user, t } = this.props;
    const type = user.type ? user.type.trim() : '';
    const element = props.causes.map(item => {
      const owner = item.society_name || item.client_name;
      return (
        <tr key={item.id}>
          <td></td>
          <td>
            <p>{t(`glosary:${item.name}`)}</p>
            <span>{t(item.type)}</span>
          </td>
          <td className="align-middle">{owner}</td>
          <td className="align-middle">{item.created_at}</td>
          <td className="align-middle">{item.rol}</td>
          <td className="align-middle">{item.judge}</td>
          <td className="align-middle">
            <Col>{this.generateAvatars(item.users)}</Col>
          </td>
          <td className="align-middle actions">
            <Link className="nav-link text-center p-0" to={`/cause/${item.id}/stages`}>{t("translation:general>show")}</Link>
            {this.deleteButton(type, item)}
          </td>
        </tr>
      )
    });
    return element;
  }

  generateAvatars(users) {
    const listUsers = [];
    if(this.props){
      users.filter(user => {
        for (let item of this.props.users){
          if ((item.id === user.user_id) || (item.id === user.id)){
            listUsers.push(item);
          }
        }
      })
      const avatars = listUsers.map(user => {
        return (
            <Avatar height={40} {...user}/>
        )
      });
      return avatars;
    }
  }
  showCreateModal(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN ){
      const { t } = this.props;
      return (
        <>
          <ButtonToolbar className="button float-right">
            <Button variant="primary" onClick={() => this.setState({modalShow: true})}>
              {t("translation:general>create")} {t("translation:general>cause").toLowerCase()} <FontAwesomeIcon className="fa-sm" icon={['far', 'plus']}/>
            </Button>
          </ButtonToolbar>
          <Modal show={this.state.modalShow} onHide={this.modalClose}>
            <CreateCauses/>
          </Modal>
        </>
      );
    }
  }
  emptyCauses = () => {
    const { user, t } = this.props;
    const type = user.type ? user.type.trim() : '';
    let message = t("translation:emptyTable>causes>message");
    let button = <></>;
    if( type === USER_TYPES.SUPER_ADMIN ){
      message = t("translation:emptyTable>causes>messageAdmin");
      button = <Button onClick={() => this.setState({ modalShow: true })} className="rounded-0">{`${t("translation:general>create")} ${t("translation:general>cause")}`}</Button>;
    }
    return (
      <Col className="pl-2" xs="7" md="3">
        <h5>
          {t("translation:emptyTable>causes>title")}
        </h5>
        <p className="text-muted">
          {message}
        </p>
        {button}
      </Col>
    )
  }

  handleNormalPagination = (page) => {
    const {id} = this.props.user.id;
    const pagination = {
      limit: 50,
      page,
    };
    this.props.getCauses(pagination);
  }

  toogleCauses = () => {
    const causes = this.props.causes.data || [];
    const { t } = this.props;
    const Table = this.generateTable;
    const EmptyCauses = this.emptyCauses;
    const DeleteModal = this.deleteModal;
    if (causes.length > 0) {
      const titles = [
        GENERAL.EMPTY,
        t("translation:general>name"),
        t("translation:general>client"),
        t("translation:general>created"),
        t("translation:general>rol"),
        t("translation:general>judge"),
        t("translation:general>users"),
        GENERAL.EMPTY,
      ];
      return (
        <Container className="pb-5 mtn-5">
          <Row>
            <Col className="col-12">
              <DeleteModal></DeleteModal>
              <Tables titles={titles}>
                <Table causes={causes}/>
              </Tables>
            </Col>
            <Pagination
            {...this.props.causes}
            handleChange={this.handleNormalPagination}/>
          </Row>
        </Container>
      )
    }
    return (
      <Container className="pb-5 mtn-3">
          <Row>
            <Col className="col-12">
              <Empty type="empty-table">
                <EmptyCauses/>
              </Empty>
            </Col>
          </Row>
      </Container>
    )
  }
  render() {
    const { t } = this.props;
    const ToogleCauses = this.toogleCauses;
    return (
      <div key="causes">
        <GreatingBar key="causes-bar" title={t("translation:general>causes")}>
        {this.showCreateModal(this.props.user)}
        </GreatingBar>
        <ToogleCauses/>
      </div>
    )
  }
}

const data = state => ({
  causes: state.cause.causes,
  user: state.user.user,
  users: state.user.users,
});

const actions = dispatch => ({
  getCauses: (pagination) => dispatch({type: GET_CAUSES, payload: {pagination}}),
  deleteCause: (causeId) => dispatch({type: DELETE_CAUSE, payload: {causeId}}),
  getUsers: () => dispatch({type: GET_USERS}),
});


export default connect(data, actions)(withTranslation()(Causes));

import React, { Component } from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, Form, Button } from 'react-bootstrap'
import { SEND_NOTIFICATION } from '../sagas/notificator/types';
import { GET_USERS } from "../sagas/user/types";
import { USER_TYPES } from '../config/constants/user';
import { USERS } from '../config/constants/filter';
import filterQueryMaker from '../helpers/filterQueryMaker.helper';

class NotModal extends Component {

  state = {
    notification: {
      subject: '',
      text: '',
    },
  }

  componentDidMount() {
    const filterParams = [
      [USERS.TYPE_NAME, USER_TYPES.SUPER_ADMIN],
    ];
    const filterQuery = filterQueryMaker(filterParams);
    this.props.getUsers(filterQuery);
  }

  handleChange = (e) => {
    const notification = this.state.notification;
    const {name, value} = e.target;
    notification[name] = value;
    this.setState({
      notification,
    })
  }

  render() {
    const { t } = this.props;
    return (
      <Modal className="rounded-0" {...this.props}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("translation:modal>request>title")}
            <h6 className="text-muted">{t("translation:modal>request>subtitle")}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPlaintextPassword">
              <Form.Label>
                {t("translation:form>subject")}
              </Form.Label>
                <Form.Control
                  name="subject"
                  onChange={e => this.handleChange(e)}
                  type="text"
                  placeholder={`${t("translation:form>enter")} ${t("translation:form>subject")}`}/>
            </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1" placeholder={`${t("translation:form>enter")} ${t("translation:form>message")}`}>
                <Form.Label>{t("translation:form>message")}</Form.Label>
                <Form.Control
                  name="text"
                  onChange={e => this.handleChange(e)}
                  as="textarea"
                  rows="3"/>
              </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="rounded-0" variant="outline-primary" onClick={this.props.onHide}>
            {t("translation:general>close")}
          </Button>
          <Button className="rounded-0" variant="primary" onClick={() => {
            const { causeInfo } = this.props;
            const { subject, text } = this.state.notification;
            this.props.users.forEach(u => {
              this.props.sendNotification({
                to: u.email,
                subject: `Requerimiento: ${causeInfo.society || causeInfo.client}/${causeInfo.name} ${subject}`,
                text: `${text} \n\n Enviado por ${this.props.user.first_name} ${this.props.user.last_name}`,
                html: `
                  <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper">
                  <tbody style="padding:2rem;">
                    <tr>
                      <td align="center" valign="top">
                      <img src="https://i.ibb.co/CntMV3V/8GRjJ8X.png" alt="logo montt" height="100" width="100"/>

                          <p style="font-size: 14px; color:#212121; margin: 50px 0 0;">
                          ${text}
                          </p>

                          <p style="font-size: 10px; font-weight:bold; text-transform: uppercase; color:#a9a9a9; margin: 30px 0 0; width: 100px;">
                            Enviado por:
                          </p>
                          <p style="font-size: 10px; font-weight:bold; text-transform: uppercase; color:#a9a9a9; margin: 0 0 20px; width: 200px;">
                            ${this.props.user.first_name} ${this.props.user.last_name}
                          </p>

                          <p style="font-size: 20px; font-weight:bold; color:#212121; margin: 50px 0 20px;" width: 50px; text-align:center;>Para acceder a la plataforma</p>
                          <a href="${window.location.href}">
                            <button
                              style="color: #fff; background: #007bFF; border: none; padding: 1rem 2rem; border-radius: 8px; text-transform: uppercase; font-weight: bold;">
                              Ir a caso
                            </button>
                          </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                `
              });
            });
            causeInfo.users.forEach(u => {
              this.props.sendNotification({
                to: u.email,
                subject: `Requerimiento: ${causeInfo.society || causeInfo.client}/${causeInfo.name} ${subject}`,
                text: `${text} \n\n Enviado por ${this.props.user.first_name} ${this.props.user.last_name}`,
                html: `
                  <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper">
                  <tbody style="padding:2rem;">
                    <tr>
                      <td align="center" valign="top">
                      <img src="https://i.ibb.co/CntMV3V/8GRjJ8X.png" alt="logo montt" height="100" width="100"/>

                          <p style="font-size: 14px; color:#212121; margin: 50px 0 0;">
                          ${text}
                          </p>

                          <p style="font-size: 10px; font-weight:bold; text-transform: uppercase; color:#a9a9a9; margin: 30px 0 0; width: 100px;">
                            Enviado por:
                          </p>
                          <p style="font-size: 10px; font-weight:bold; text-transform: uppercase; color:#a9a9a9; margin: 0 0 20px; width: 200px;">
                            ${this.props.user.first_name} ${this.props.user.last_name}
                          </p>

                          <p style="font-size: 20px; font-weight:bold; color:#212121; margin: 50px 0 20px;" width: 50px; text-align:center;>Para acceder a la plataforma</p>
                          <a href="${window.location.href}">
                            <button
                              style="color: #fff; background: #007bFF; border: none; padding: 1rem 2rem; border-radius: 8px; text-transform: uppercase; font-weight: bold;">
                              Ir a caso
                            </button>
                          </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                `
              });
            });
            this.props.onHide();
          }}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const data = state => ({
  users: state.user.users,
  user: state.user.user,
  causeInfo: state.cause.causeInfo,
  task: state.task.task,
  causeCatastroFiles: state.cause.causeCatastroFiles,
  title: state.stage.title,
})
const actions = dispatch => ({
  sendNotification: (data) => dispatch({type: SEND_NOTIFICATION, payload: data}),
  getUsers: (filter) => dispatch({type: GET_USERS, filter: filter}),
});

export default connect(data, actions)(withTranslation()(NotModal))

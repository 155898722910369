import {takeLatest, call, put} from 'redux-saga/effects'
import filterQueryMaker from '../../helpers/filterQueryMaker.helper';
import { CLIENT_ID, ORDER } from '../../config/constants/filter';
import { CAUSES } from '../../config/constants/filter';
import {
  GET_PROJECTS,
  GET_PROJECT,
  GET_PROJECT_CAUSES,
  CREATE_PROJECT,
  FILL_PROJECT_TASK,
  ADD_PROJECT_CAUSE,
} from "./types";
import {
  FETCH_PROJECTS,
  FETCH_PROJECT,
  FETCH_PROJECT_CAUSE,
} from "../../stores/project/types";
import {
  projects,
  project,
  create_project,
  fill_task,
  add_cause,
} from "../../network/projects";
import {
  causes,
} from "../../network/causes";

function* getProjects() {
  try {
    const {data} = yield call(projects);
    yield put({type: FETCH_PROJECTS, payload: data})
  } catch (e) {
    console.log(e)
  }
}

function* getProject(action) {
  try {
    const {payload} = action;
    const response = yield call(project, payload.id);
    let query = filterQueryMaker([[CAUSES.PROJECT_ID, response.data.id]]);
    query += payload.order ? ORDER + payload.order : '';
    const {data} = yield call(causes, query);
    response.data.causes = data.data;
    yield put({type: FETCH_PROJECT, payload: response.data})
  } catch (e) {
    console.log(e)
  }
}

function* getProjectCauses(action) {
  const {payload} = action;
  try {
    let query = payload.filter ? filterQueryMaker(payload.filter) : '';
    query += payload.clientId ? CLIENT_ID + payload.clientId : '';
    query += payload.order ? ORDER + payload.order : '';
    const {data} = yield call(causes, query);
    const listCauses = data.data;
    yield put({type: FETCH_PROJECT_CAUSE, payload: listCauses})
  } catch (e) {
    console.log(e)
  }
}

function* createProject(action) {
  const {payload} = action;
  try {
    const {data} = yield call(create_project, payload);
    window.location.href = `/project/${data.id}/causes`;
  } catch (e) {
    console.log(e);
  }
}

function* fillProjectTask(action) {
  const {payload} = action;
  try {
    const {data} = yield call(fill_task, payload);
    yield put({type: FETCH_PROJECT, payload: data});
  } catch (e) {
    console.log(e);
  }
}

function* addCause(action) {
  const {projectId, payload} = action;
  try {
    const projectResponse = yield call(add_cause, projectId, payload);
    let query = filterQueryMaker([[CAUSES.PROJECT_ID, projectId]]);
    query += ORDER + 'lastUpdated';
    const {data} = yield call(causes, query);
    projectResponse.data.causes = data.data;
    yield put({type: FETCH_PROJECT, payload: projectResponse.data});
  } catch (e) {
    console.log(e);
  }
}

function* watchGetProjects() {
  yield takeLatest(GET_PROJECTS, getProjects)
}

function* watchGetProject() {
  yield takeLatest(GET_PROJECT, getProject)
}

function* watchGetProjectCauses() {
  yield takeLatest(GET_PROJECT_CAUSES, getProjectCauses)
}

function* watchCreateProject() {
  yield takeLatest(CREATE_PROJECT, createProject)
}

function* watchFillProjectTask() {
  yield takeLatest(FILL_PROJECT_TASK, fillProjectTask)
}

function* watchAddCause() {
  yield takeLatest(ADD_PROJECT_CAUSE, addCause)
}

export default [
  watchGetProjects(),
  watchGetProject(),
  watchGetProjectCauses(),
  watchCreateProject(),
  watchFillProjectTask(),
  watchAddCause(),
]

import React, { Component } from 'react'
import {connect} from "react-redux";
import { Container, Row, Col, Button, Badge } from 'react-bootstrap';
import CardCol from '../../../components/card';
import Tables from '../../../components/tables';
import CustomModal from '../../../components/modal';
import Pagination from '../../../components/pagination';
import { GET_CAUSE_NORMAL_FILES } from "../../../sagas/cause/types";
import { GET_FILES_CAUSE  } from "../../../sagas/file/types";
import { GENERAL } from '../../../config/constants/tableTitles';
import { DOCUMENT } from '../../../config/constants/states';
import { USER_TYPES } from '../../../config/constants/user';
import Empty from '../../../components/emptyCard';
import UploadModal from '../../../components/modal.upload';
import { withTranslation } from "react-i18next";

const PENDING_STATE = 0;
const FIRST_PAGE = 1;
const { CATASTRO } = DOCUMENT;

class Documents extends Component {

  state = {
    modalShow: false,
    currentFile: {}
  };

  componentWillMount() {
    const {id} = this.props.match.params;
    const pagination = {
      limit: 5,
      page: 1,
    };
    this.props.getCauseNormalFiles(id, pagination);
  }

  handleClick(file_id) {
    const {id} = this.props.match.params;
    this.props.getFile(id, file_id);
  }

  handleNormalPagination = (page) => {
    const {id} = this.props.match.params;
    const pagination = {
      limit: 5,
      page,
    };
    this.props.getCauseNormalFiles(id, pagination);
  }

  generateTable = (props) => {
    const files = props.content || [];
    const { t } = this.props;
    return files.map((item) => {
      const stageName = item.stage || item.substage;
      return(
        <tr>
          <td></td>
          <td className="align-middle text-left">
            <p>{item.file_name}</p>
            <span>{t(item.name)}</span>
          </td>
          <td className="align-middle text-left">
            <p>{t(stageName)}</p>
          </td>
          <td className="align-middle text-left">
            {this.generateState(item.state)}
          </td>
          <td className="align-middle text-left">
            <p>{item.upload_date}</p>
          </td>
          <td className="align-middle float-right actions">
            <Button variant="link"
            onClick={(e) => this.handleClick(item.file_id)}
            {...(item.state === PENDING_STATE) ? {disabled: true} : {}}>
              {t("translation:general>show")}
            </Button>
            {(this.props.user.type.includes(USER_TYPES.ADMIN))
              ? (
                <Button variant="link" onClick={() => this.setState({ currentFile: item, modalShow: true })}>
                  {(item.state === PENDING_STATE) ? t("translation:general>upload") : t("translation:general>edit")}
                </Button>
              )
              : <React.Fragment></React.Fragment>
              }
          </td>
        </tr>
      )
    })
  }

  generateState(state) {
    const { t } = this.props;
    switch (state) {
    case CATASTRO.ABARCAMIENTO.NUMBER:
      return (
      <Badge className={`danger-custom rounded-0`}>
        {t("translation:states>surveillance>outreach").toUpperCase()}
      </Badge>
      )
    case CATASTRO.OPOSICION.NUMBER:
      return (
      <Badge className={`danger-custom rounded-0`}>
        {t("translation:states>surveillance>opposition").toUpperCase()}
      </Badge>
      )
    case CATASTRO.SUPERPOSICION.NUMBER:
      return (
      <Badge className={`danger-custom rounded-0`}>
        {t("translation:states>surveillance>overlap").toUpperCase()}
      </Badge>
      )
    case CATASTRO.ENLARGMENT.NUMBER:
      const enlargmentName = t("translation:states>surveillance>enlargment").split(' ').shift();
      return (
      <Badge className={`danger-custom rounded-0`}>
        {t(enlargmentName).toUpperCase()}
      </Badge>
      )
    case CATASTRO.REDUCTION.NUMBER:
      const reductionName = t("translation:states>surveillance>reduction").split(' ').shift();
      return (
      <Badge className={`danger-custom rounded-0`}>
        {reductionName.toUpperCase()}
      </Badge>
      )
    case CATASTRO.UPLOADED.NUMBER:
      return (
      <Badge className={`success-custom rounded-0`}>
        {t("translation:states>surveillance>uploaded").toUpperCase()}
      </Badge>
      )
    case CATASTRO.UPDATE.NUMBER:
      return (
      <Badge className={`success-custom rounded-0`}>
        {t("translation:states>surveillance>update").toUpperCase()}
      </Badge>
      )
    default:
      return (
      <Badge className={`warning-custom rounded-0`}>
        {t("translation:states>surveillance>empty").toUpperCase()}
      </Badge>
      )
  }
  }

  modalClose = () => {
    this.setState({modalShow: false});
    this.handleNormalPagination(FIRST_PAGE);
  }

  showCreateModal = () => {
    const type = this.props.user.type ? this.props.user.type.trim() : '';
    if (type === USER_TYPES.SUPER_ADMIN) {
      const { t } = this.props;
      return (
        <>
          <CustomModal show={this.state.modalShow} onHide={this.modalClose}>
            <UploadModal
              title={t("translation:modal>documents>title")}
              subtitle={t("translation:modal>documents>subtitle")}
              closeModal={this.modalClose}
              {...this.props}
              file={this.state.currentFile}/>
          </CustomModal>
        </>
      )
    }
    return (<React.Fragment/>)
  }

  emptyAlertTable = () => {
    const { t } = this.props;
    return (
    <Col className="pl-2" xs="7" md="3">
      <h5>
        {t("translation:emptyTable>cause>documents>title")}
      </h5>
      <p className="text-muted">
        {t("translation:emptyTable>cause>documents>subtitle")}
      </p>
    </Col>
  )}

  toogleTables = () => {
    const Table = this.generateTable;
    const EmptyTable = this.emptyAlertTable;
    const normalFiles = this.props.causeNormalFiles.data || [];
    const { t } = this.props;
    if (normalFiles.length > 0) {
      return (
      <Tables titles={[
        GENERAL.EMPTY,
        t("translation:general>name"),
        t("translation:general>stage"),
        t("translation:general>state"),
        t("translation:general>date"),
        GENERAL.EMPTY
        ]}>
        <Table content={normalFiles}/>
      </Tables>
      )
    }
    return (
      <Empty type="empty-table">
        <EmptyTable/>
      </Empty>
    )
  }

  render() {
    const UpdateModal = this.showCreateModal;
    const { t } = this.props;
    return (
      <>
      <Container className="pb-5 info">
        <Row className="mtn-5">
          <Col className="col-12">
            <UpdateModal/>
            <CardCol className="info__general" title={t("translation:general>causeFiles")} button="" to="#">
              {this.toogleTables()}
            </CardCol>
          </Col>
          <Pagination
            {...this.props.causeNormalFiles}
            handleChange={this.handleNormalPagination}/>
        </Row>
      </Container>
      </>
    )
  }
}

const actions = dispatch => ({
  getCauseNormalFiles: (id, pagination) => dispatch({type: GET_CAUSE_NORMAL_FILES, payload: {id, pagination}}),
  getFile: (cause_id, file_id) => dispatch({type: GET_FILES_CAUSE, payload: {cause_id, file_id}})
});

const data = state => ({
  uploadState: state.file.uploadFile,
  causeNormalFiles: state.cause.causeNormalFiles,
  user: state.user.user,
});

export default connect(data, actions)(withTranslation()(Documents));

import React, {Component} from 'react';
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {Col, Row, Form, Button} from "react-bootstrap";
import {GET_CLIENTS} from "../../sagas/client/types";
import {CREATE_SOCIETY} from "../../sagas/society/types";
import {
  NAME,
  RUT,
  PHONE,
  ADDRESS,
  EMAIL,
} from "../../config/constants/formAttributes"

const attributes = [
  NAME,
  RUT,
  PHONE,
  ADDRESS,
  EMAIL,
]

class CreateSociety extends Component {
  componentWillMount(){
    if(this.props.clients.length === 0){
      this.props.getClients();
    }
  }
  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    this.props.createSociety(Object.fromEntries(data));
    this.props.closeModal();
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={e => this.handleSubmit(e)}>
        <Row className="pt-3 pb-4">
        <Col className="col-12" xl={5}>
            <div className="title p-2">
              <h4>{t("translation:modal>societies>title")}</h4>
              <p>{t("translation:modal>societies>subtitle")}</p>
            </div>
        </Col>
        <Col className="col-12" xl={7}>
        {attributes.map(item => {
          return <Form.Group>
              <Form.Label htmlFor={item.id}>{t(`translation:form>${item.label}`)}</Form.Label>
              <Form.Control
                onChange={e => this.handleChange(e)}
                className="rounded-0"
                {...item}
                placeholder={`${t("translation:form>enter")} ${t(`translation:form>${item.label}`).toLowerCase()}`} required/>
            </Form.Group>
        })}
        <Form.Group>
          <Form.Label> {t("translation:form>selectClient")}</Form.Label>
          <Form.Control
            as="select"
            className="text-capitalize"
            name="client_id"
            required
            onChange={e => this.handleChange(e)}>
            <option value="">{t("translation:form>selectClient")}</option>
            {this.props.clients.map((el, i) =>
              <option key={i} value={el.id}>{el.name}</option>
            )}
          </Form.Control>
        </Form.Group>
          <Button variant="btn btn-primary rounded-0 float-right" type="submit">{t("translation:general>create")}</Button>
        </Col>
      </Row>
        </form>
      </React.Fragment>
    );
  }
}

const data = state => ({
  clients: state.client.clients,
});

const actions = dispatch => ({
  getClients: () => dispatch({type: GET_CLIENTS}),
  createSociety: (society) => dispatch({type: CREATE_SOCIETY, payload: society})
});

export default connect(data, actions)(withTranslation()(CreateSociety));

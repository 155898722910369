import React, { Component } from 'react'
import { Col }from 'react-bootstrap'

export default class Information extends Component {

  switchInfo = () => {
    switch(this.props.type) {
      case "textfield_rol":
        return (
          <Col xs={4} lg="auto">
            <p className="small">{this.props.title}</p>
            <h6>{this.props.value}</h6>
          </Col>
        )
      case "select_resource_mininfo":
          return (
            <Col xs={4} lg="auto" className="w-145">
              <p className="small">{this.props.title}</p>
              <h6>{this.props.value}</h6>
            </Col>
          )
      case "alarm_date_info":
          return (
            <Col xs={4} lg="auto">
              <p className="small">{this.props.title}</p>
              <h6>{this.props.value}</h6>
            </Col>
          )
      default: 
        return (
          <Col xs={4} lg="auto">
            <p className="small">{this.props.title}</p>
            <h6>{this.props.value}</h6>
          </Col>
        )
    }
  }

  render() {
    const Switch = this.switchInfo;
    return (
      <Switch/>
    )
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  Row,
  Col,
  Modal,
  Container,
  Form,
  Button,
} from "react-bootstrap";
import { GET_CAUSES } from "../../sagas/cause/types";
import { CAUSES } from "../../config/constants/filter";
import { ADD_PROJECT_CAUSE } from "../../sagas/project/types";
import { withTranslation } from "react-i18next";

class AddCause extends Component {
  componentWillMount() {
    const query = [[CAUSES.DIAGRAM_NAME, "Servidumbre"]];
    const { clients, clientName } = this.props;
    const clientId = clients && clientName ? clients.find((c) => c.name === clientName).id  : null;
    this.props.getCauses(query, clientId);
    console.log(this.props)
  }
  handleSubmit(e){
    e.preventDefault();
    const { addCause, modalClose, projectId } = this.props;
    const data = new FormData(e.target);
    const obj = Object.fromEntries(data);
    addCause(projectId, obj);
    modalClose();
  };
  selectFilter(){
    const { causesAdded } = this.props;
    const newCauses = [];
    const currentCauses = Array.isArray(this.props.causes.data)
      ? this.props.causes.data
      : [];
    currentCauses.forEach((obj) => {
      const finded = causesAdded.find((el) => el.id === obj.id);
      if (!finded) {
        newCauses.push(obj);
      }
    });
    return newCauses;
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <Modal.Header closeButton>
          <h5>{`${t("translation:general>add")} ${t(
            "translation:general>cause"
          ).toLowerCase()}`}</h5>
        </Modal.Header>
        <Card className="right-content">
          <Container>
            <Row className="pt-2 pb-4">
              <Col xl={12}>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                  <Form.Group>
                    <Form.Label>{t("translation:form>selectCause")}</Form.Label>
                    <Form.Control
                      as="select"
                      className="text-capitalize"
                      name="cause_id"
                      required
                    >
                      {this.selectFilter().map((el, i) => (
                        <option key={i} value={el.id}>
                          {el.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Button
                    variant="btn btn-primary rounded-0 float-right"
                    type="submit"
                  >
                    {t("translation:general>add")}
                  </Button>
                </form>
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  }
}

const data = (state) => ({
  causes: state.cause.causes,
});

const actions = (dispatch) => ({
  getCauses: (query, clientId) =>
    dispatch({ type: GET_CAUSES, payload:{ filter: query, clientId: clientId }}),
  addCause: (projectId, payload) =>
    dispatch({
      type: ADD_PROJECT_CAUSE,
      projectId: projectId,
      payload: payload,
    }),
});

export default connect(data, actions)(withTranslation()(AddCause));

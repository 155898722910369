export const HEADER = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
};

export const DASHBOARD = {
  ALERT_TABLE_TITLES: {
    ALERT: 'alerta',
    CASE: 'caso',
    TIME: 'tiempo',
    STAGE: 'etapa',
    STATE: 'estado',
  }
};

export const CHECK_TASK_CAUSES = {
  NEXT_TASKS_AMOUNT: {
    TWO: 1,
    THREE: 2,
  },
  PAYMENT: {
    FIRST_TASK: 'Reporte',
    SECOND_TASK: 'Comprobante',
  },
  CONCESSION: {
    FIRST_TASK: 'Concesión',
  },
}

export const ATTRIBUTE_TYPE = {
  CHECK_UNIQUE_SUBSTAGE: 'check_unique_substage',
  SELECT: 'select',
  SELECT_MININFO: 'select_mininfo',
  SELECT_RESOURCE: 'select_resource',
  SELECT_RESOURCE_MININFO: 'select_resource_mininfo',
  SELECT_RESOURCE_INFO: 'select_resource_info',
  TEXTFILED_TASK_NAME: 'textfield_task_name',
  SELECT_TASK: 'select_task',
  DATE: 'date',
  DATEFIELD: 'datefield',
  DATE_INFO: 'date_info',
  ALARM_DATE: 'alarm_date',
  ALARM_DATE_INFO: 'alarm_date_info',
  CHECK: 'checkbox',
  CHECK_TASK: 'checkbox_task',
  CHECK_UNIQUE: 'checkbox_unique',
  FILE: 'upload',
  INPUT: 'input',
  NAME: 'textfield_name',
  ROL: 'textfield_rol',
  COURT: 'court',
  TEXTFIELD_AREA: 'textfield_area',
  TEXTFIELD: 'text',
}

export const ADD_USER_RADIO_BUTTON = {
  LABEL: 'Típo Usuario',
  ADMIN: 'Admin',
  CLIENT_SOCIETY_USER: 'Usuario asociado',
}

import axios from './config'

export const users = async (filter) => await axios.get(`/b/p/users${filter}`, {
  headers: {
    service: "AUTH"
  }
});

export const delete_user = async (id) => await axios.delete('/b/p/users/' + id,
  {
    headers: {
      service: "AUTH"
    }
  });

export const avatar = async (file) => {
  let formdata = new FormData();
  formdata.append('avatar', file);
  formdata.set('fileName', 'avatar');

  const response = await axios.post('/b/p/file/account/avatar', formdata, {
    headers: {
      service: "AUTH",
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const users_by_client_society = async (clientSocietyId) => await axios.get(`/b/p/users?client_society_id=${clientSocietyId}`, {
  headers: {
    service: "CLIENT"
  }
});

export const user = async () => await axios.get(`/b/p/account/profile`, {
  headers: {
    service: "AUTH"
  }
});

export const types = async () => await axios.get(`/b/p/oauth/types`, {
  headers: {
    service: "AUTH"
  }
});

export const create_user = async (user) => await axios.post(`/b/p/register`,user, {
  headers: {
    service: "AUTH"
  }
});

export const set_type = async (userId, type) => await axios.post(`/b/p/users/${userId}/type`,type, {
  headers: {
    service: "AUTH"
  }
});

export const set_client_society_user = async (body) => await axios.post(`/b/p/users`,body, {
  headers: {
    service: "CLIENT"
  }
});
export const set_client_user = async (body) => await axios.post(`/b/p/clients/users`,body, {
  headers: {
    service: "CLIENT"
  }
});
export const set_society_user = async (body) => await axios.post(`/b/p/societies/users`,body, {
  headers: {
    service: "CLIENT"
  }
});

export const set_user_permission = async (body) => await axios.post(`/b/p/user`,body, {
  headers: {
    service: "CORE"
  }
});

export const update_user_profile = async (body) => await axios.patch(`/b/p/account/profile`, body, {
  headers: {
    service: "AUTH"
  }
});

export const update_user = async (body) => await axios.patch(`/b/p/account/user`, body, {
  headers: {
    service: "AUTH"
  }
});

export const update_user_password = async (body) => await axios.post(`/b/p/account/password`, body, {
  headers: {
    service: "AUTH"
  }
});

export const SIZE_COLUMN = 6;
export const SERVIDUMBRE = {
  CONVENCIONAL: 'Servidumbre Convencional',
  JUDICIAL: 'Servidumbre Judicial',
  ADMINISTRATIVA: 'Servidumbre Administriva u Otra',
  ESTATAL: 'Servidumbre Administrativa Estatal',
  PRIVADA: 'Servidumbre Administrativa Privada',
}
export const PROJECTS = {
  CONCESSIONS: 'Listado de Concesiones / Plantas',
}

import React, {Component} from 'react'
import {Col, Row, Card, Container, Form, Button, Table} from 'react-bootstrap';
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { ATTRIBUTE_TYPE } from '../../config/constants/components'
import attributeValueManager from '../../helpers/attributeValueManager';
import TaskTable from './taskTable.causes';
import { GET_FILES_CAUSE  } from "../../sagas/file/types";

function FilesTable(props) {
  const { files, t } = props;
  return files.map((item) => {
    return(
      <tr>
        <td className="align-middle text-left">
          <p>{t(item.label)}</p>
          <span>{(item.name === "") ? t("translation:general>notEnteredDocument") : item.name}</span>
        </td>
        <td className="align-middle float-right actions">
          <Button
            variant="btn"
            className="btn-link"
            disabled={(item.name === "")}
            onClick={() => props.handleClick(item.file_id)}>
            {t("translation:general>show")}
          </Button>
        </td>
      </tr>
    )
  })
}

class InfoCard extends Component {

  state = {
    attributeValues: [],
    errors: [],
  };

  checkTaskValue(props, task){
    const { nextTask, next_stage_id } = task;
    const { value, placeholder} = props;
    const response = placeholder.split(' ');
    const nt = nextTask.find(obj => obj.id === value);
    if(nt && !next_stage_id)
    {
      return ( nt.is_default ? response[0] : response[1]);
    }
    else if (nt && next_stage_id){
      return response[1];
    } else {
      return response[0];
    }
  }

  infoCol(props, errors, task, t) {
      let value = props.value || '-';
      if (props.type === ATTRIBUTE_TYPE.SELECT_TASK && props.value) value = task.nextTask.find(obj => obj.id === value).name;
      else if (props.type === ATTRIBUTE_TYPE.CHECK_TASK && props.value) value = this.checkTaskValue(props,task);
      else value = attributeValueManager(props);
      console.log(value);
      return (
        <Col key={props.id} className="col-12 mb-2 title" md={6}>
          <span className="mb-2">{t(props.label)}</span>
          <p>{t(value)}</p>
        </Col>
      )
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (JSON.stringify(nextProps.attributeValues) !== JSON.stringify(this.state.attributeValues)) {
      this.setState({
        attributeValues: nextProps.attributeValues,
      })
    }
  }

  generateAttributes(attributes, task, errors) {
    const { t } = this.props;
    return attributes.map((item, index) => {
      return this.infoCol(item,errors, task, t);
    })
  }

  handleClick = (file_id) => {
    const { id } = this.props.cause;
    this.props.getFile(id, file_id);
  }

  render() {
    const { attributeValues, errors} = this.state;
    const { task, t } = this.props;
    return (
      <Col className="col-12" lg={7} xl={8}>
        <Card className="right-content">
          <Container>
          <Row className="mb-4 mt-4">
          <Col className="col-12 mb-4" xl={4}>
            <div className="title p-2"><span>{t("translation:general>tasks")}</span> </div>
            <TaskTable tasks={this.props.actualTasks} />
          </Col>

          <Col className="col-12 mb-4" xl={8}>
          <div className="title mt-2">
            <span>{t("translation:general.task")}</span>
            <h4>{t(this.props.task.name)}</h4>
            <p>{t(this.props.task.description)}</p>
          </div>
          <div className="title mt-2"><span>{t("translation:general>form")}</span>
          </div>
            <Form.Row className="">
              {this.generateAttributes(attributeValues, task, errors)}
            </Form.Row>
          </Col>
              {(this.props.files.length > 0)
                ? (
                  <Col className="col-12">
                    <div className="info__customers">
                      <Table hover>
                        <thead>
                          <tr>
                            <th className="bb-0" width="100%">{t("translation:general>name")}</th>
                            <th className="bb-0"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <FilesTable t={t} files={this.props.files} handleClick={this.handleClick}/>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  )
                : <React.Fragment/>
            }
          </Row>
          </Container>
        </Card>
      </Col>
    )
  }
}

const data = state => ({
  task: state.task.task,
  actualTasks: state.stage.actualTasks,
  attributeValues: state.task.task.hasOwnProperty('attributeValues')
    ? state.task.task.attributeValues
    : [],
  files: state.task.task.hasOwnProperty('files')
    ? state.task.task.files
    : [],
  cause: state.cause.cause,
  causeInfo: state.cause.causeInfo,
  alertByCause: state.alert.alertByCause,
});

const actions = dispatch => ({
  getFile: (cause_id, file_id) => dispatch({type: GET_FILES_CAUSE, payload: {cause_id, file_id}})
});

export default connect(data, actions)(withTranslation()(InfoCard))

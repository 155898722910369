export const GET_USERS = "sagas/user/GET_USERS";
export const GET_USER = "sagas/user/GET_USER";
export const GET_TYPES = "sagas/user/GET_TYPES";
export const SET_PERMISSION = "sagas/user/SET_PERMISSION";
export const CREATE_USER = "sagas/user/CREATE_USER";
export const ASSOCIATE_USER = "sagas/user/ASSOCIATE_USER";
export const GET_USERS_BY_CLIENT_SOCIETY = "sagas/user/GET_USERS_BY_CLIENT_SOCIETY";
export const UPDATE_PROFILE = "sagas/user/UPDATE_PROFILE";
export const UPDATE_PASSWORD = "sagas/user/UPDATE_PASSWORD";
export const DELETE_USER = "sagas/user/DELETE_USER";
export const EDIT_USER = "sagas/user/EDIT_USER";
export const STATES = {
  STATUS: {
    SUCCESS: 0,
    WAITING: 100,
    UNAUTHORIZE: 401,
  },
}

export const ALERT = {
  LOW: 'success',
  HIGH: 'danger',
  MEDIUM: 'warning',
}

export const TASK = {
  COMPLETE_AND_CREATE_NEXT: 1,
  CONTINUE_AND_CREATE_NEXT: 2,
  CONTINUE: 3,
  COMPLETE: 4,
  EMPTY: 0,
  COMPLETE_TITLE: 8,
};

export const STAGE = {
  EMPTY_STATE: 0,
  INCOMPLETE_STATE: 1,
  ACTUAL: 3,
  COMPLETE_STATE: 2,
  TITLE: 100,
  PAYMENT: 101,
  NEGOTIATION: 102,
  CONCESSION: 103,
};

export const DOCUMENT = {
  CATASTRO: {
    ABARCAMIENTO: {
      NAME: 'Abarcamiento',
      NUMBER: 4,
    },
    OPOSICION: {
      NAME: 'Oposición',
      NUMBER: 2,
    },
    SUPERPOSICION: {
      NAME: 'Superposición',
      NUMBER: 3,
    },
    ENLARGMENT: {
      NAME: 'Ampliación del área solicitada',
      NUMBER: 6,
    },
    REDUCTION: {
      NAME: 'Reducción del área solicitada',
      NUMBER: 7,
    },
    EMPTY: {
      NAME: 'En Espera',
      NUMBER: 0,
    },
    UPLOADED: {
      NAME: 'Cargado',
      NUMBER: 1,
    },
    UPDATE: {
      NAME: 'Actualización',
      NUMBER: 5,
    },
  },
  COLOR: {
    RED: 'red',
    YELLOW: 'yellow',
    GREEN: 'green',
  },
  SUCCESS: 'success',
  ERROR: 'danger',
  SENDING: 'warning',
  PROGRESS: {
    OK: 100,
    ERROR: 100,
    SENDING: 10,
    EMPTY: 0,
  },
  DESCRIPTION: {
    FIRST: 'Superposición a derecho minero',
    SECOND: 'Superposición a ANP y/o zona de amortiguamiento',
    THIRD: 'Superposición a área agrícola',
    FOURTH: 'Superposición a zona urbana y/o zona de expansión urbana',
    FIFTH: 'Superposición a zona de frontera',
    SIX: 'Superposición a línea de frontera o fuera del territorio nacional',
    SEVEN: 'Superposición a área de forestación',
    EIGHT: 'Superposición a proyectos especiales',
    NINE: 'Simultaneidad de petitorios mineros',
    TEN: 'Actualización',
    ELEVEN: 'Se ha ampliado por nuevos propietarios',
    TWELVE: 'Se redujo por cambio de propietario',
    THIRTEEN: 'Se ha ampliado el proyecto',
    FOURTEEN: 'Se redujo el proyecto',
    FIFTEEN: 'Se modificó el trazado del proyecto',
    SIXTEEN: 'Se ha actualizado',

  },
}

export const PROJECT = {
  NOT_COMPLETE: 0,
  COMPLETE: 1,
}

import React from 'react'
import {connect} from 'react-redux'
import {
  Route,
  Redirect,
} from 'react-router-dom'


const props = state => ({isLoggedIn: state.user.isLoggedIn});

const _PrivateRoute = ({component: Component, ...rest}) => {

  return (<Route {...rest} render={(props) => {
    console.log('state', rest.isLoggedIn);
    return (rest.isLoggedIn === true
      ? <Component {...props} />
      : <Redirect to='/login'/>
  )}}/>)
};


const _PublicRoute = ({component: Component, ...rest}) => {
  console.log('PASA', rest.isLoggedIn);
  return (<Route {...rest} render={function (props) {
    console.log('PROPS', props);
      return(
    rest.isLoggedIn === false
      ? <Component {...props} />
      : <Redirect to='/dashboard'/>
  )}}/>)
};


export const PrivateRoute = connect(props, null)(_PrivateRoute);
export const PublicRoute = connect(props, null)(_PublicRoute);

